import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { createDtcList } from './index'
import { dtcItemKey } from '../helpers/utils'
import { getElementName } from '../helpers/element'

export const getSelectedDtcs = (currentDtcs) =>
  createSelector([createDtcList], (dtcList) => {
    const currentDtcsSet = _.reduce(
      currentDtcs,
      (s, item) => s.add(item.dtc),
      new Set(),
    )
    return _.chain(dtcList)
      .filter((dtc) => currentDtcsSet.has(dtc.dtc_code))
      .map((dtc) => ({
        value: dtcItemKey(dtc.module, dtc),
        label: dtcItemKey(dtc.module, dtc),
        dtcCode: dtc.dtc_code,
      }))
      .value()
  })

export const getDtcOptions = createSelector([createDtcList], (dtcList) =>
  dtcList.map((dtc) => ({
    value: dtcItemKey(dtc.module, dtc),
    label: dtcItemKey(dtc.module, dtc),
    dtcCode: dtc.dtc_code,
  })),
)

const getComponents = (state) => state.componentState.componentsForModel
const getNodes = (state) => state.componentState.graphData.nodes
const getOnlyHarnessesWithLevels = (state) =>
  _.filter(
    state.harnessState.harnessesForModel,
    (h) => state.harnessState.harnessLevels[h.id.toUpperCase()],
  )

export const getComponentsList = createSelector([getComponents], (components) =>
  _.map(components, (component) => ({
    label: `${getElementName(component)} ${component.description} ${component.id}`,
    description: component.description,
    value: component.alias + component.description,
    id: component.id,
  })),
)

export const getDevicesList = createSelector([getNodes], (nodes) => {
  const devicesOnly = nodes.filter((node) => node.data.category === 'device')
  const formattedDevices = devicesOnly.map((node) => ({
    label: `${getElementName(node.data)}`,
    description: node.data.description,
    value: node.data.id,
    id: node.data.id,
  }))
  return formattedDevices
})

export const getHarnessList = createSelector(
  [getOnlyHarnessesWithLevels],
  (harnesses) =>
    _.map(harnesses, (harness) => ({
      label: harness.description,
      description: harness.description,
      value: harness.id,
      id: harness.id,
    })),
)
