import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/api/',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
  InterceptorOptions.noCacheInterceptor,
)

export async function getPowerDistributionInformation(vin, componentId, pin) {
  const url = `/power-distribution/vin/${vin}/component-id/${componentId}/pin/${pin}`
  const { data } = await api.get(url)
  if (data) {
    return data
  }
  return null
}

export async function getFuseDetails({ makeModelId, fuseName }) {
  const url = `/power-distribution/make-model-id/${makeModelId}/fuse-name/${fuseName}`
  const { data } = await api.get(url)
  if (data.data || Object.keys(data.data).length != 0) {
    return data.data
  }
  return null
}
