import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { Result, ConfigProvider, Flex } from 'antd'
import classNames from 'classnames'
import { Breadcrumb, Button, Spin } from 'antd'
import { DetailDiagram } from '../BlockDiagram'
import { useDiagram, useDiagramDispatch } from './DiagramContext'
import { ComponentOrLocationImage, LocationImage } from './Image'
import ConnectorPinout from './ConnectorPinout/DetailDiagramPinout'
import CircuitColorIcon from './CircuitColorIcon'
import ComponentNeighbors from './ComponentNeighbors'
import { RelatedSubsystemsButton } from '../components/RelatedSubsystemsButton'
import { FormattedMessage } from 'react-intl'
import { useTerminologyVIN } from '../hooks/Terminology'
import { useMakeModelByVin } from '../hooks/MakeModels'
import {
  RelatedDTCsModal,
  RelatedDTCsButton,
  CircuitDTCsProvider,
} from '../CircuitDTCs'
import {
  LayoutOutlined,
  HomeOutlined,
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import { NoSelection } from './index'

import './style.scss'

const DetailInfoPinPanel = () => {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const [isImagePanelExanded, setImagePanelExanded] = React.useState(true)

  return (
    <Flex vertical={false} style={{ height: '100%' }}>
      <div
        className={classNames('functional-diagrams-detail-pin-info-table', {
          expand: !isImagePanelExanded,
        })}
      >
        <ConnectorPinout pageSize={8} />
      </div>
      <Flex
        className={classNames(
          'functional-diagrams-detail-pin-info-image-panel',
          { minimize: !isImagePanelExanded },
        )}
      >
        <div className="functional-diagrams-detail-pin-info-image-divider">
          <Button
            size="small"
            shape="circle"
            icon={isImagePanelExanded ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => setImagePanelExanded(!isImagePanelExanded)}
          />
        </div>
        <div className="functional-diagrams-detail-pin-info-image">
          <ComponentOrLocationImage
            componentId={diagram.detailDiagram.data.componentId}
            componentImageName={diagram.detailDiagram.data.imageName}
            locationImageName={diagram.detailDiagram.data.locationImageName}
            pins={[diagram.detailDiagram.data.pin]}
            description={diagram.detailDiagram.data.componentDescription}
            alias={diagram.detailDiagram.data.componentAlias}
            isImagePanelExanded={isImagePanelExanded}
          >
            {diagram.detailDiagram.data.category !== 'inline' && (
              <RelatedSubsystemsButton
                showButtonText={false}
                vin={diagram.vin}
                componentId={diagram.detailDiagram.data.componentId}
                contextComponentId={
                  diagram.detailDiagram.relatedSubsystemComponentID
                }
                componentIdDispatch={dispatch.handleRelatedSubsystemsButton}
              />
            )}
          </ComponentOrLocationImage>
        </div>
      </Flex>
    </Flex>
  )
}

const DetailInfoCircuit = () => {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const {
    circuit,
    circuit_description,
    colors,
    harness,
    harness_description,
    fromComponentImageName,
    toComponentImageName,
    fromPin,
    toPin,
    fromComponentCategory,
    toComponentCategory,
    fromComponent,
    toComponent,
    toLocationImageName,
    fromLocationImageName,
    fromComponentDescription,
    toComponentDescription,
    toComponentAlias,
    fromComponentAlias,
    isVirtual,
  } = diagram.detailDiagram.data
  const [firstColor, secondColor] = colors.hex_triplets

  const [showSpliceLocationImage, updateShowSpliceLocationImage] =
    useState(false)

  const shouldHideInlineDetails =
    fromComponentCategory !== 'inline' ||
    toComponentCategory !== 'inline' ||
    (!isVirtual &&
      fromComponentCategory == 'inline' &&
      toComponentCategory == 'inline')
      ? true
      : false

  const { data, isLoading } = useTerminologyVIN({
    vin: diagram.vin,
    codes: harness,
  })

  const { isLoading: isMakeModelByVinLoading, makeModel } = useMakeModelByVin({
    vin: diagram.vin,
  })

  const harnessDescription =
    data && data[harness] && data[harness].description
      ? data[harness].description
      : harness_description

  const spliceLocationImageDisplayHandler = () => {
    updateShowSpliceLocationImage(!showSpliceLocationImage)
  }

  return (
    <>
      <Flex
        className="functional-diagrams-info-panel-legend"
        gap="large"
        align={'center'}
      >
        {shouldHideInlineDetails && (
          <div>
            <h5>
              <FormattedMessage id="functionalDiagrams.detailDiagramView.circuit" />
            </h5>
            {circuit}
          </div>
        )}
        {shouldHideInlineDetails || (
          <div>
            <h5>
              <FormattedMessage id="functionalDiagrams.detailDiagramView.inline" />
            </h5>
            {`${toComponentAlias} - ${fromComponentAlias}`}
          </div>
        )}
        {shouldHideInlineDetails && (
          <div>
            <h5>
              <FormattedMessage id="functionalDiagrams.detailDiagramView.harness" />
            </h5>
            {isLoading ? (
              <Spin
                indicator={<LoadingOutlined spin />}
                size="small"
                style={{ color: 'var(--color-text)' }}
              />
            ) : (
              harnessDescription
            )}
          </div>
        )}
        <div>
          <h5>
            <FormattedMessage id="functionalDiagrams.detailDiagramView.description" />
          </h5>
          {circuit_description}
        </div>
        {shouldHideInlineDetails && (
          <Flex vertical={true}>
            <h5>
              <FormattedMessage id="functionalDiagrams.detailDiagramView.color" />
            </h5>
            <Flex vertical={false} gap="small">
              <div>{colors.letter_code}</div>
              <div>
                <CircuitColorIcon
                  firstColor={firstColor}
                  secondColor={secondColor}
                />
              </div>
            </Flex>
          </Flex>
        )}
        <CircuitDTCsProvider>
          <RelatedDTCsButton
            loading={isMakeModelByVinLoading}
            circuit={circuit}
            makeModelId={makeModel?.id}
          />
          <RelatedDTCsModal />
        </CircuitDTCsProvider>
        {((fromComponentCategory == 'splice' && fromLocationImageName) ||
          (toComponentCategory == 'splice' && toLocationImageName)) && (
          <div data-cy="circuit-splice-toggle-button">
            <Button
              type={showSpliceLocationImage ? 'default' : 'primary'}
              onClick={spliceLocationImageDisplayHandler}
            >
              <LayoutOutlined />
            </Button>
          </div>
        )}
      </Flex>
      <Flex
        vertical={false}
        className="detail-diagrams-circuit-info-component-images"
      >
        <div className="detail-diagrams-circuit-info-from-component">
          {fromComponentCategory === 'splice' ? (
            fromLocationImageName && showSpliceLocationImage ? (
              <div
                data-cy="circuit-splice-location-image"
                style={{ height: '100%' }}
              >
                <LocationImage
                  fileName={fromLocationImageName}
                  componentId={fromComponent}
                />
              </div>
            ) : (
              <div data-testid="component-neighbour-table">
                <ComponentNeighbors
                  componentId={fromComponent}
                  vin={diagram.vin}
                />
              </div>
            )
          ) : (
            <ComponentOrLocationImage
              componentImageName={fromComponentImageName}
              pins={[fromPin]}
              componentId={fromComponent}
              locationImageName={fromLocationImageName}
              description={fromComponentDescription}
              alias={fromComponentAlias}
            >
              {fromComponentCategory !== 'inline' && (
                <RelatedSubsystemsButton
                  showButtonText={false}
                  vin={diagram.vin}
                  componentId={fromComponent}
                  contextComponentId={
                    diagram.detailDiagram.relatedSubsystemComponentID
                  }
                  componentIdDispatch={dispatch.handleRelatedSubsystemsButton}
                />
              )}
            </ComponentOrLocationImage>
          )}
        </div>
        <div className="detail-diagrams-circuit-info-to-component">
          {toComponentCategory === 'splice' ? (
            toLocationImageName && showSpliceLocationImage ? (
              <div
                data-cy="circuit-splice-location-image"
                style={{ height: '100%' }}
              >
                <LocationImage
                  fileName={toLocationImageName}
                  componentId={toComponent}
                />
              </div>
            ) : (
              <div data-testid="component-neighbour-table">
                <ComponentNeighbors
                  componentId={toComponent}
                  vin={diagram.vin}
                />
              </div>
            )
          ) : (
            <ComponentOrLocationImage
              componentImageName={toComponentImageName}
              pins={[toPin]}
              componentId={toComponent}
              locationImageName={toLocationImageName}
              description={toComponentDescription}
              alias={toComponentAlias}
            >
              {toComponentCategory !== 'inline' && (
                <RelatedSubsystemsButton
                  showButtonText={false}
                  vin={diagram.vin}
                  componentId={toComponent}
                  contextComponentId={
                    diagram.detailDiagram.relatedSubsystemComponentID
                  }
                  componentIdDispatch={dispatch.handleRelatedSubsystemsButton}
                />
              )}
            </ComponentOrLocationImage>
          )}
        </div>
      </Flex>
    </>
  )
}

const DetailInfoComponentPanel = () => {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const {
    alias,
    description,
    componentId,
    locationImageName: detailDiagramLocationImageName,
    category: detailDiagramCategory,
  } = diagram.detailDiagram.data

  const {
    componentId: componentNeighborId,
    imageName: componentNeighborImageName,
    locationImageName: componentNeighborLocationImageName,
    pin: componentNeighborPin,
    componentDescription,
    componentAlias,
    category,
  } = diagram.detailDiagram.componentNeighbor.data

  const shouldShowSpliceImage =
    detailDiagramCategory == 'splice' && detailDiagramLocationImageName

  const renderRightPanel = () => {
    if (!shouldShowSpliceImage && !componentNeighborId) {
      return <NoSelection />
    } else if (shouldShowSpliceImage && !componentNeighborId) {
      return (
        <div data-cy="splice-location-image" style={{ height: '100%' }}>
          <LocationImage
            fileName={detailDiagramLocationImageName}
            componentId={componentId}
          />
        </div>
      )
    } else if (componentNeighborId) {
      return (
        <ComponentOrLocationImage
          componentId={componentNeighborId}
          componentImageName={componentNeighborImageName}
          locationImageName={componentNeighborLocationImageName}
          pins={[componentNeighborPin]}
          description={componentDescription}
          alias={componentAlias}
        >
          {category !== 'inline' && (
            <RelatedSubsystemsButton
              showButtonText={false}
              vin={diagram.vin}
              componentId={componentNeighborId}
              contextComponentId={
                diagram.detailDiagram.relatedSubsystemComponentID
              }
              componentIdDispatch={dispatch.handleRelatedSubsystemsButton}
            />
          )}
        </ComponentOrLocationImage>
      )
    }
  }

  return (
    <div className="functional-diagrams-detail-info">
      <Flex
        className="functional-diagrams-info-panel-legend"
        gap="large"
        align={'center'}
      >
        <div>
          <h5>
            <FormattedMessage id="functionalDiagrams.detailDiagramView.detailInfoComponentPanel.alias" />
          </h5>
          {alias}
        </div>
        <div>
          <h5>
            <FormattedMessage id="functionalDiagrams.detailDiagramView.detailInfoComponentPanel.description" />
          </h5>
          {description}
        </div>
      </Flex>
      <Flex className="detail-diagrams-component-info">
        <div className="detail-diagrams-component-info-table">
          <div className="component-neighbors-table-container">
            <ComponentNeighbors
              componentId={componentId}
              vin={diagram.vin}
              showSelection={diagram.detailDiagram.selection === 'component'}
              pageSize={7}
            />
          </div>
        </div>
        <div className="detail-diagrams-component-info-image">
          {renderRightPanel()}
        </div>
      </Flex>
    </div>
  )
}

function DiagramDetailView() {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const props = useSelector((state) => {
    return {
      theme: state.app.theme,
    }
  }, shallowEqual)

  const InfoPanelRouter = React.useCallback(() => {
    let ComponentToRender

    switch (diagram.detailDiagram.selection) {
      case 'pin':
        ComponentToRender = DetailInfoPinPanel
        break
      case 'circuit':
        ComponentToRender = DetailInfoCircuit
        break
      case 'component':
        ComponentToRender = DetailInfoComponentPanel
        break
      default:
        ComponentToRender = NoSelection
    }

    return <ComponentToRender />
  }, [diagram.detailDiagram.selection])

  return (
    <Flex vertical={true} style={{ height: '100%' }}>
      <div className="functional-diagram-right-panel-header">
        <div className="functional-diagrams-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined
                className="clickable"
                onClick={() => dispatch.handleDiagramReset()}
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={classNames({
                clickable: true,
              })}
              onClick={() => dispatch.blockDiagram.reset()}
            >
              {diagram.subsystem?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {diagram.blockDiagram.graph.data.fromComponentAlias} -{' '}
              {diagram.blockDiagram.graph.data.toComponentAlias}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="functional-diagrams-right-panel-content">
        <div style={{ height: '45%' }}>
          <ErrorBoundary
            resetKeys={[
              diagram.vin,
              diagram.blockDiagram.graph.data.fromComponent,
              diagram.blockDiagram.graph.data.toComponent,
            ]}
            fallback={
              <ConfigProvider
                theme={{
                  token: {
                    colorTextHeading: 'rgb(255, 255, 255)',
                    colorTextDescription: 'rgb(255, 255, 255)',
                  },
                }}
              >
                <Flex
                  vertical={false}
                  align="middle"
                  className="functional-diagrams-error-boundary-result"
                >
                  <Result
                    status="warning"
                    subTitle="Sorry, something went wrong"
                  />
                </Flex>
              </ConfigProvider>
            }
          >
            <DetailDiagram
              key={`${diagram.vin}-${diagram.blockDiagram.graph.data.fromComponent}-${diagram.blockDiagram.graph.data.toComponent}`}
              vin={diagram.vin}
              fromComponent={diagram.blockDiagram.graph.data.fromComponent}
              toComponent={diagram.blockDiagram.graph.data.toComponent}
              theme={props.theme}
            />
          </ErrorBoundary>
        </div>
        <div className="functional-diagrams-detail-info-panel">
          <InfoPanelRouter />
        </div>
      </div>
    </Flex>
  )
}

export default DiagramDetailView
