import { useSearchDTCsByProblemLog } from '../hooks/DTC'
import { ProblemList } from './ProblemList'
import { useEffect, useState } from 'react'

export const FaultDetails = ({ fault, intl }) => {
  const { dtcs } = useSearchDTCsByProblemLog(fault.id)

  const [moduleString, setModuleString] = useState('')

  useEffect(() => {
    if (dtcs && dtcs.length > 0) {
      setModuleString(
        dtcs
          .map((dtc) => {
            return `${dtc.acronym} - ${dtc.dtc}`
          })
          .join(', '),
      )
    } else {
      setModuleString('')
    }
  }, [dtcs])

  return (
    <div id="fault-details">
      <div id="fault-details-summary">
        {fault.relatedIssues && fault.relatedIssues.length > 0 && (
          <div id="past-issues" className="fault-details-left">
            <span className="section-title">
              {intl.formatMessage({
                id: 'incidentMgmt.faultDetails.pastIssues',
                defaultMessage: 'Past Issues',
              })}
              &nbsp;:&nbsp;
            </span>
            <ul>
              {fault.relatedIssues.forEach((issue) => {
                return (
                  <li>
                    <a>{issue.id}</a>
                  </li>
                )
              })}
            </ul>
            <span className="avoid-duplicates">
              {intl.formatMessage({
                id: 'incidentMgmt.faultDetails.avoidDupes',
                defaultMessage: 'Please check to avoid duplicates',
              })}
            </span>
          </div>
        )}
        <div id="fault-problem-description" className="fault-details-left">
          <span className="section-title">
            {intl.formatMessage({
              id: 'incidentMgmt.faultDetails.problemCode',
              defaultMessage: 'Problem Code',
            })}
            &nbsp;:&nbsp;
          </span>
          <span className="section-content" id="fault-reason">
            <span className="fault-problem-code">{fault.problem_code}</span>
            <span className="fault-description">
              {fault.description} - {fault.fault_key}
            </span>
          </span>
        </div>
        <div id="affected-modules" className="fault-details-right">
          <span className="section-title">
            {intl.formatMessage({
              id: 'incidentMgmt.faultDetails.modules',
              defaultMessage: 'Modules',
            })}{' '}
            - DTCs&nbsp;:&nbsp;
          </span>
          <span className="section-content" id="fault-modules">
            {moduleString}
          </span>
        </div>
        <div id="from-station" className="fault-details-right">
          <span className="section-title">
            {intl.formatMessage({
              id: 'incidentMgmt.faultDetails.station',
              defaultMessage: 'Station',
            })}
            &nbsp;:&nbsp;
          </span>
          <span className="section-content" id="fault-station">
            {fault.station_id || '--'}
          </span>
        </div>
      </div>
      {fault.problems && <ProblemList problems={fault.problems} />}
    </div>
  )
}
