import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import IconDemo from '../Icons/IconDemo'
import IconReport from '../Icons/IconReport'
import IconDashboard from '../Icons/IconDashboard'
import IconFunctionalDiagrams from '../Icons/IconFunctionalDiagrams'
import IconSearch from '../Icons/IconSearch'
import IconVinSearch from '../Icons/IconVinSearch'
import IconUsers from '../Icons/IconUsers'
import IconComponentSearch from '../Icons/IconComponentSearch'
import IconDtcMemo from '../Icons/IconDtcMemo'
import IconProductionLine from '../Icons/IconProductionLine'
import IconIncidentMgmt from '../Icons/IconIncidentManagment'
import { ROUTING, FUNCTIONAL_DIAGRAMS_FLAG } from '../../constants'
import DashboardCarousel from './DashboardCarousel'
import useWindowSize from '../../hooks/WindowSize'
import { useCheckPermission } from '../../hooks/Permissions'
import { useFeatureFlags } from '../../hooks/FeatureFlags'
import Loading from '../Loading'
import { Card, Col, Flex } from 'antd'

const { Meta } = Card

// If you change this you have to change @media(max-width: 740px)
// in _main-dashboard.scss
const THREE_ITEMS_PER_PAGE_WIDTH = 740

function MainMenu() {
  const { isLoading, usersFeatureFlags } = useFeatureFlags()
  const [colSize, updateColSize] = useState(8)
  const [url, setUrl] = useState(null)
  const { width } = useWindowSize()
  const checkPermission = useCheckPermission()
  const wrapperClassNames = 'main-dashboard-button'

  useEffect(() => {
    if (width <= 740) {
      updateColSize(24)
    } else if (width <= 1060) {
      updateColSize(12)
    } else {
      updateColSize(8)
    }
  }, [width])

  if (isLoading) return <Loading />

  const elements = [
    checkPermission('page-gettingstarted:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          id="nav-to-getting-started"
          key="nav-to-getting-started"
          className={wrapperClassNames}
          onClick={() => setUrl('getting-started')}
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconDashboard />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="gettingStarted.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-report:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          id="nav-to-failure-report"
          key="nav-to-failure-report"
          className={wrapperClassNames}
          onClick={() => setUrl('/reports')}
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconReport />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="faultReport.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-incidents:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-incidents"
          className={wrapperClassNames}
          onClick={() => setUrl('/incidents')}
          id="nav-to-incidents"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconIncidentMgmt />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="incidentMgmt.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-search:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-vehicle-search"
          className={wrapperClassNames}
          onClick={() => setUrl('/search')}
          id="nav-to-vehicle-search"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconVinSearch />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="vinSearch.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-demo:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-demo"
          className={wrapperClassNames}
          onClick={() => setUrl('/demo')}
          id="nav-to-demo"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconDemo />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="demoTracer.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    usersFeatureFlags.includes(FUNCTIONAL_DIAGRAMS_FLAG) &&
      checkPermission('page-functional-diagrams:get') && (
        <Col span={colSize} className="main-dashboard-button-wrapper">
          <Card
            key="nav-to-functional-diagrams"
            className={wrapperClassNames}
            onClick={() => setUrl(ROUTING.functionalDiagrams)}
            id="nav-to-functional-diagrams"
            hoverable
            cover={
              <div className="main-dashboard-icon">
                <IconFunctionalDiagrams />
              </div>
            }
          >
            <div className="card-label">
              {<FormattedMessage id="functionalDiagrams.routeLabel" />}
            </div>
          </Card>
        </Col>
      ),
    checkPermission('page-component-search:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-component-search"
          className={wrapperClassNames}
          onClick={() => setUrl('/global-search/configuration')}
          id="nav-to-component-search"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconSearch />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="globalSearch.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-production-line-dashboard:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-production-line-dashboard"
          className={wrapperClassNames}
          onClick={() => setUrl('/production-line-dashboard')}
          id="nav-to-production-line-dashboard"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconProductionLine />
            </div>
          }
        >
          <div className="card-label">
            <span>
              {<FormattedMessage id="productionLineDashboard.routeLabel" />}
            </span>
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-user-administration:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-user-administration"
          className={wrapperClassNames}
          onClick={() => setUrl('/users')}
          id="nav-to-user-administration"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconUsers />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="userAdministration.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('page-alerts:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-alerts"
          className={wrapperClassNames}
          onClick={() => setUrl('/alerts')}
          id="nav-to-alerts"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconVinSearch />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="alerts.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('manual-alerts:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-manual-alerts"
          className={wrapperClassNames}
          onClick={() => setUrl('/manual-alerts')}
          id="nav-to-manual-alerts"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconDtcMemo />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="manualAlerts.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
    checkPermission('qrcode-scan:get') && (
      <Col span={colSize} className="main-dashboard-button-wrapper">
        <Card
          key="nav-to-qrcode-scan"
          className={wrapperClassNames}
          onClick={() => setUrl('/qrcode-scan')}
          id="nav-to-qrcode-scan"
          hoverable
          cover={
            <div className="main-dashboard-icon">
              <IconVinSearch />
            </div>
          }
        >
          <div className="card-label">
            {<FormattedMessage id="qrCodeScan.routeLabel" />}
          </div>
        </Card>
      </Col>
    ),
  ]

  if (url) {
    return <Redirect push to={url} />
  }

  // For larger screens we have 6 items per page,
  // for smaller vertical screens we have 3 items per page.
  const itemsPerPage = width <= THREE_ITEMS_PER_PAGE_WIDTH ? 3 : 6

  return <DashboardCarousel elements={elements} perPage={itemsPerPage} />
}

export default MainMenu
