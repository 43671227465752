import React, { Component } from 'react'
import { Formik } from 'formik'
import { injectIntl, FormattedMessage } from 'react-intl'
import { DisabledField, RoleField, PlantField, Buttons } from './Fields'

import { Flex, Typography } from 'antd'
import { Loadable } from '../Loading'

const { Title } = Typography

const getUserPlant = (user) =>
  user?.plants?.length > 0 ? user.plants[0] : 'none'

class EditUser extends Component {
  requestUserEdition = (formValues) => {
    const { updateUser } = this.props
    const userData = {
      role: formValues.role,
      disabled: formValues.disabled,
      plant: formValues.plant,
    }
    updateUser(userData)
  }

  validateForm = (values) => {
    const errors = {}
    if (!values.role || values.role.length === 0) {
      errors.role = 'Required'
    }

    if (!errors.role && !values.plant) {
      errors.plant = 'Required'
    }
    return errors
  }

  render() {
    const { roles, plants, isSaving, isLoading, editingUser } = this.props
    const roleOptions = roles.map((role) => ({ value: role, label: role }))
    const plantOptions = plants.map((plant) => ({ value: plant, label: plant }))
    const disabledOptions = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ]
    const initialValues = {
      role: editingUser.role,
      plant: [],
      disabled: editingUser.disabled,
    }
    if (isLoading) {
      return <Loadable isLoading={true} showText={true} />
    }

    return (
      <Flex id="user-form-container" vertical={true} align="center" gap="small">
        <Title level={2}>
          <FormattedMessage id="users.editUser" /> {editingUser.email}
        </Title>
        <Formik initialValues={initialValues} validate={this.validateForm}>
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Flex vertical={true} gap="middle">
                  <RoleField
                    options={roleOptions}
                    value={values.role}
                    onChange={(option) => setFieldValue('role', option)}
                    error={touched.role && errors.role}
                  />

                  <PlantField
                    role={values.role}
                    options={plantOptions}
                    onChange={(option) => setFieldValue('plant', option)}
                    value={values.plant}
                    error={touched.role && errors.plant}
                  />

                  <DisabledField
                    options={disabledOptions}
                    onChange={(option) => setFieldValue('disabled', option)}
                    value={values.disabled}
                  />

                  <Buttons
                    isSaving={isSaving}
                    values={values}
                    onSubmit={this.requestUserEdition}
                  />
                </Flex>
              </form>
            )
          }}
        </Formik>
      </Flex>
    )
  }
}

export default injectIntl(EditUser)
