import React from 'react'
import { withRouter } from 'react-router'
import history from '../../history'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatTimestamp } from '../../helpers/utils'
import NavigationTabs from '../Navigation/NavigationTabs'
import {
  ADD_DTC_MEMO_VIEW,
  DTC_CROWDSOURCED_EDIT_NAMESPACE,
  DTC_MAPPING_ACTIVE_UI,
  MANAGE_MAPPINGS_VIEW,
} from '../../constants'
import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import { useDTCMemo, useUpdateDTCMemo } from '../../hooks/DTCMemo'
import { useDeleteDTCMapping } from '../../hooks/CrowdsourcedDTCMapping'
import { useActiveDTCMapping } from '../../hooks/ActiveDTCMapping'
import MustHave from '../../containers/MustHave'
import Forbidden from '../Forbidden'
import { requireAuthentication } from '../../helpers/authentication'
import DTCMemo from '../DTCMemo'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { AddConnectorMapping } from './AddConnectorMapping'
import AddConnectorMappingStartComponent from './AddConnectorMappingStartComponent'
import { EditDTCMappingsHeader } from './CrowdsourcedDTCMapping'
import Loading from '../Loading'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Layout, Row, Col, Button, Table, Flex, Typography } from 'antd'

const MemoAndMappingContent = ({ dtcMemoId }) => {
  const { Title } = Typography
  const { Content } = Layout
  const dispatch = useDispatch()
  const { dtcMemo, isLoading, isSuccess } = useDTCMemo(dtcMemoId)
  const updateDTCMemo = useUpdateDTCMemo()

  return (
    <>
      {isLoading && <Loading useBackgroundColor={false} />}
      {isSuccess && (
        <Layout className="dtc-memo-mapping-container">
          <Content>
            <Row className="memo-mapping-header">
              <Col span={10}>
                <Button icon={<ArrowLeftOutlined />} onClick={history.goBack}>
                  <FormattedMessage id="generic.back" />
                </Button>
              </Col>
              <Col span={14}>
                <Flex vertical="false">
                  <NavigationTabs namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE} />
                </Flex>
              </Col>
            </Row>
            <Row className="dtc-memo-mapping-content">
              <Col span={24}>
                <TabContent>
                  <TabPane
                    id={ADD_DTC_MEMO_VIEW}
                    position={0}
                    canBeDeactivated={false}
                    namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE}
                  >
                    <DTCMemo
                      memoId={dtcMemoId}
                      initialMemo={
                        history.location.state?.initialMemo ?? dtcMemo.memo
                      }
                      initialShortDescription={
                        history.location.state?.initialShortDescription ??
                        dtcMemo.short_desc
                      }
                      acronym={dtcMemo.acronym}
                      makeModelStr={dtcMemo.make_model_str}
                      dtcError={dtcMemo.dtc}
                      dtcId={dtcMemo.dtc_id}
                      onSubmit={updateDTCMemo}
                    />
                  </TabPane>
                  <TabPane
                    id={MANAGE_MAPPINGS_VIEW}
                    position={1}
                    canBeDeactivated={false}
                    namespace={DTC_CROWDSOURCED_EDIT_NAMESPACE}
                  >
                    <Flex
                      style={{ height: '100%' }}
                      vertical
                      align="center"
                      gap="small"
                    >
                      <Row className="dtc-memo-header">
                        <Col span="24">
                          <EditDTCMappingsHeader dtcMemoId={dtcMemoId} />
                        </Col>
                      </Row>
                      <Row className="dtc-mapping-content">
                        <Col span="24">
                          <Flex
                            vertical={false}
                            justify="space-between"
                            className="manage-mapping-title"
                            align="center"
                          >
                            <Title level={5}>
                              <FormattedMessage id="crowdsourcedDtcMapping.currentMappingLabel" />
                            </Title>
                            <Button
                              onClick={() =>
                                dispatch(
                                  setActiveUIComponent(
                                    DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT,
                                  ),
                                )
                              }
                            >
                              <FormattedMessage id="crowdsourcedDtcMapping.newMappingButton" />
                            </Button>
                          </Flex>
                          <DTCMapping
                            makeModelId={dtcMemo.make_model_id}
                            dtcCode={dtcMemo.dtc}
                            acronym={dtcMemo.acronym}
                          />
                        </Col>
                      </Row>
                    </Flex>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    </>
  )
}

const DTCMapping = injectIntl(function ({
  makeModelId,
  dtcCode,
  acronym,
  intl,
}) {
  const { activeDTCMappings, isLoading, isSuccess } = useActiveDTCMapping({
    makeModelId,
    dtcCode,
    acronym,
  })
  const deleteDTCMapping = useDeleteDTCMapping()
  const dateTimeFormat = intl.formatMessage({ id: 'format.timestampFull' })

  const renderStartComponents = (start_components) => {
    return start_components.map((start_component, index) => (
      <React.Fragment key={`${start_component.id}-${index}`}>
        <strong>{start_component.id}</strong>
        <br />
        {start_component?.description}
        <br />
      </React.Fragment>
    ))
  }

  const columns = [
    {
      title: (
        <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.destination" />
      ),
      width: 200,
      render: (text, record) => (
        <>
          <strong>{record.destination_component.id}</strong>
          <br />
          {record.destination_component.description}
        </>
      ),
    },
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.start" />,
      render: (text, record) => (
        <>
          {record.start_components.length > 0
            ? renderStartComponents(record.start_components)
            : 'Destination Only'}
        </>
      ),
    },
    {
      title: (
        <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.updatedat" />
      ),
      render: (text, record) => (
        <>{formatTimestamp(dateTimeFormat, record.updated_at)}</>
      ),
    },
    {
      title: (
        <FormattedMessage id="crowdsourcedDtcMapping.dtcMapping.updatedby" />
      ),
      dataIndex: ['updated_by'],
    },
    {
      title: '',
      render: (text, record) => (
        <>
          {record.mapping_type === 'crowdsourced' && (
            <Button onClick={() => deleteDTCMapping.mutate(record)} danger>
              <FormattedMessage id="crowdsourcedDtcMapping.delete" />
            </Button>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      {isLoading && 'Loading'}
      {isSuccess && (
        <Table
          pagination={{
            pageSize: 5,
            hideOnSinglePage: true,
          }}
          columns={columns}
          dataSource={activeDTCMappings.data}
          rowKey={(record) =>
            `${record.make_model_id}-${record.dtc_code}-${record.acronym}-${record.destination_component.id}-${record.start_components}`
          }
        />
      )}
    </>
  )
})

const DTCMemoAndMappingWrapper = ({
  match: {
    params: { dtcMemoId, vin },
  },
}) => {
  // State from dtcMappingReducer
  const props = useSelector((state) => state.dtcMapping, shallowEqual)

  let InnerContent

  switch (props.activeUIComponent) {
    case DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT:
      InnerContent = AddConnectorMapping
      break
    case DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_START:
      InnerContent = AddConnectorMappingStartComponent
      break
    default:
      InnerContent = MemoAndMappingContent
  }

  return (
    <>
      <MustHave permission="dtc-memo:get" fallback={<Forbidden />}>
        <InnerContent dtcMemoId={dtcMemoId} vin={vin} />
      </MustHave>
    </>
  )
}

export default withRouter(requireAuthentication(DTCMemoAndMappingWrapper))
