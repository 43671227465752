import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

export const GlobalSearchButton = ({
  vin = null,
  showVINInTooltip = false,
  disabled = false,
}) => {
  const history = useHistory()
  const intl = useIntl()

  return (
    <Tooltip
      title={
        vin && showVINInTooltip
          ? intl.formatMessage(
              { id: 'globalSearch.buttonTootipVINText' },
              {
                vin,
              },
            )
          : intl.formatMessage({ id: 'globalSearch.buttonTootipText' })
      }
    >
      <Button
        color="primary"
        size="large"
        icon={<SearchOutlined />}
        onClick={() =>
          vin
            ? history.push(`/global-search/vin/${vin}`)
            : history.push('/global-search/configuration')
        }
        disabled={disabled}
      />
    </Tooltip>
  )
}
