import React from 'react'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import Topology from '../SvgViews/Topology'
import {
  MODAL_CANT_SAVE_START_COMPONENT,
  DTC_MAPPING_ACTIVE_UI,
  MODAL_CONNECTOR_VIEW,
  BLACK_COLOR,
} from '../../constants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SvgCircle from '../SvgCircle'
import { getPinDestination } from '../../api/controller-api'
import {
  selectPin,
  clearPinDestination,
  clearSelectedPin,
} from '../../actions/pin-actions'
import { unsetModal } from '../../actions/modal-actions'
import { SET_MODAL_CONTAINER } from '../../actions/action-types'
import CloseButton from '../CloseButton'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { selectElement } from '../../thunks/pin'
import PinDestinationGraphContainer from '../../containers/PinDestinationGraphContainer'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import {
  useAddDTCMapping,
  useActivateDTCMapping,
} from '../../hooks/CrowdsourcedDTCMapping'
import { useDTCMemo } from '../../hooks/DTCMemo'
import PinDetails from '../PinDetails'
import { setModal, setConnectorImage } from '../../actions/modal-actions'
import { StartComponentHeader } from './CrowdsourcedDTCMapping'
import { Table, Button, Flex, Layout, Row, Col, Radio, Modal } from 'antd'
import { Loadable } from '../../components/Loading'

export const PinsTable = ({ vin, componentId, dtcMemoId, intl }) => {
  const addDTCMapping = useAddDTCMapping()
  const activateDTCMapping = useActivateDTCMapping()

  const props = useSelector(
    (state) => ({
      ...state.dtcMapping, // components, selectedComponent, pins, jumperPins, etc.
      ...state.pinState, // pinNumber, currentPin, componentId, pinDestination, etc.
    }),
    shallowEqual,
  )

  const { dtcMemo } = useDTCMemo(dtcMemoId)
  const dispatch = useDispatch()
  const { componentDetails, isLoading, isSuccess } = useComponentDetails({
    vin,
    componentId,
  })
  const currentUser = useSelector(
    (state) => state.user.data.username,
    shallowEqual,
  )

  const selectPinOfComponent = (pin) => {
    if (pin.originalCavityNumber) {
      dispatch(clearPinDestination())
      dispatch(clearSelectedPin())
      getPinDestination(vin, componentDetails.component.id, pin.cavity)
      dispatch(selectPin(pin.cavity, pin, componentDetails.component.id))
      dispatch(selectElement(componentDetails.component))
      dispatch(
        setActiveUIComponent(
          DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_START,
        ),
      )
    }
  }
  const saveDestinationOnly = () => {
    addDTCMapping.mutate(
      {
        destinationComponentId: componentDetails.component.id,
        makeModelId: dtcMemo.make_model_id,
        dtcCode: dtcMemo.dtc,
        acronym: dtcMemo.acronym,
        updatedBy: currentUser,
      },
      {
        onSuccess: () => {
          dispatch(setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS))
        },
        onError: (error, variables, context) => {
          if (error.response.status === 409) {
            activateDTCMapping.mutate(
              { id: error.response.data.existing_id, updatedBy: currentUser },
              {
                onSuccess: () => {
                  dispatch(
                    setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
                  )
                },
              },
            )
          }
        },
      },
    )
  }

  const columns = [
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.columns.pin" />,
      dataIndex: ['cavity'],
      width: '70px',
    },
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.columns.color" />,
      render: (record) => {
        return (
          <Flex align="middle">
            <SvgCircle colors={record.colors} /> {record.color_desc}
          </Flex>
        )
      },
      width: '120px',
    },
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.columns.category" />,
      dataIndex: ['circuit_category'],
      width: '100px',
    },
    {
      title: (
        <FormattedMessage id="crowdsourcedDtcMapping.columns.description" />
      ),
      dataIndex: ['signal_desc'],
      ellipsis: true,
      width: '220px',
    },
    {
      title: '',
      render: (record) => {
        return (
          !record.isEmpty && (
            <Button
              testid="select-start-button"
              onClick={() => {
                selectPinOfComponent(record)
              }}
              type="primary"
              style={{ whiteSpace: 'normal', height: 'auto' }}
              size="small"
            >
              <FormattedMessage id="crowdsourcedDtcMapping.selectStart" />
            </Button>
          )
        )
      },
      width: '120px',
    },
  ]

  return (
    <div className="mapping-table-wrapper">
      <Flex
        className="mapping-table-header"
        justify="space-between"
        align="center"
      >
        <FormattedMessage id="crowdsourcedDtcMapping.pins" />{' '}
        {isLoading && <FormattedMessage id="crowdsourcedDtcMapping.loading" />}
        <Button
          onClick={saveDestinationOnly}
          disabled={isLoading || !componentDetails}
        >
          <FormattedMessage id="crowdsourcedDtcMapping.saveDestinationOnly" />
        </Button>
      </Flex>
      <Table
        pagination={{
          pageSize: 3,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        columns={columns}
        dataSource={componentDetails ? componentDetails.pins : []}
        rowKey={(record) => record.key}
      />
    </div>
  )
}

const HARNESS_VIEW = 'harness_view'
const VEHICLE_VIEW = 'vehicle_view'

const ViewBtnGroup = ({ view, setView }) => {
  const onChange = ({ target: { value } }) => {
    setView(value)
  }

  const options = [
    {
      label: <FormattedMessage id="crowdsourcedDtcMapping.harnessView" />,
      value: HARNESS_VIEW,
    },
    {
      label: <FormattedMessage id="crowdsourcedDtcMapping.vehicleView" />,
      value: VEHICLE_VIEW,
    },
  ]

  return (
    <Radio.Group
      options={options}
      onChange={onChange}
      value={view}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export const CantSavePopup = injectIntl(function ({ intl }) {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(unsetModal())
  const isOpen = useSelector((state) => state.modalState.isOpen, shallowEqual)
  const selectedElement = useSelector(
    (state) => state.pinState.selectedElement,
    shallowEqual,
  )

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="crowdsourcedDtcMapping.cantSave.header" />}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
      ]}
      centered={true}
    >
      <div>
        <FormattedMessage
          id="crowdsourcedDtcMapping.cantSave.body"
          values={{
            alias: selectedElement.alias,
            description: selectedElement.description,
          }}
        />
      </div>
    </Modal>
  )
})

const AddConnectorMappingStartComponent = ({ vin, dtcMemoId }) => {
  const { Header, Content } = Layout
  const addDTCMapping = useAddDTCMapping()
  const activateDTCMapping = useActivateDTCMapping()
  const props = useSelector(
    (state) => ({
      ...state.dtcMapping, // components, selectedComponent, pins, jumperPins, etc.
      ...state.pinState, // pinNumber, currentPin, componentId, pinDestination, etc.
    }),
    shallowEqual,
  )
  const pinDestination = useSelector((state) => state.pinState.pinDestination)

  const currentUser = useSelector(
    (state) => state.user.data.username,
    shallowEqual,
  )
  const dispatch = useDispatch()

  const { dtcMemo, isSuccess } = useDTCMemo(dtcMemoId)
  const pinState = useSelector((state) => state.pinState, shallowEqual)

  const [view, setView] = React.useState(HARNESS_VIEW)

  const openCantSaveModal = () => {
    dispatch({
      type: SET_MODAL_CONTAINER,
      container: MODAL_CANT_SAVE_START_COMPONENT,
    })
  }

  const saveMapping = () => {
    if (props.selectedComponentId === pinState.selectedElement.id) {
      openCantSaveModal()
      return
    }
    addDTCMapping.mutate(
      {
        destinationComponentId: props.selectedComponentId,
        makeModelId: isSuccess && dtcMemo.make_model_id,
        dtcCode: isSuccess && dtcMemo.dtc,
        acronym: isSuccess && dtcMemo.acronym,
        startComponentId: pinState.selectedElement.id,
        updatedBy: currentUser,
      },
      {
        onSuccess: () =>
          dispatch(setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS)),
        onError: (error, variables, context) => {
          if (error.response.status === 409) {
            activateDTCMapping.mutate(
              { id: error.response.data.existing_id, updatedBy: currentUser },
              {
                onSuccess: () => {
                  dispatch(
                    setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
                  )
                },
              },
            )
          }
        },
      },
    )
  }

  return (
    <>
      <Header className="start-component-header">
        <Flex vertical={false} gap="middle" justify="space-between">
          <StartComponentHeader dtcMemoId={dtcMemoId} vin={vin} />
          <Flex
            vertical={false}
            gap="middle"
            justify="space-between"
            align="center"
          >
            <Button
              onClick={() =>
                dispatch(
                  setActiveUIComponent(
                    DTC_MAPPING_ACTIVE_UI.ADD_NEW_MAPPING_SELECT_COMPONENT,
                  ),
                )
              }
            >
              <FormattedMessage id="crowdsourcedDtcMapping.back" />
            </Button>
            <Button
              disabled={!pinState.selectedElement.id}
              onClick={saveMapping}
              type="primary"
            >
              <FormattedMessage id="crowdsourcedDtcMapping.save" />
            </Button>
          </Flex>
        </Flex>
      </Header>
      <Content className="start-component-content-container">
        <Loadable isLoading={isEmpty(pinDestination)}>
          <Row vertical={false} className="start-component-content">
            <Col span={18} className="start-component-content-left-pane">
              <Flex vertical>
                <Flex
                  justify="center"
                  className="start-component-content-left-pane-view-selector"
                >
                  <ViewBtnGroup setView={setView} view={view} />
                </Flex>
                <div className="start-component-content-left-pane-view">
                  {view === HARNESS_VIEW ? (
                    <PinDestinationGraphContainer />
                  ) : (
                    <div className="topology-container">
                      <Topology component={pinState.selectedElement} />
                    </div>
                  )}
                </div>
              </Flex>
            </Col>
            <Col span={6} className="start-component-content-right-pane">
              <PinDetails
                selectedElement={pinState.selectedElement}
                showConnectorModal={(pins, imageUrl) => {
                  dispatch(setConnectorImage(pins, imageUrl))
                  dispatch(setModal(MODAL_CONNECTOR_VIEW))
                }}
              />
            </Col>
          </Row>
        </Loadable>
      </Content>
    </>
  )
}

export default AddConnectorMappingStartComponent
