import { useQuery } from 'react-query'
import {
  apiGetRelatedDTCs,
  apiGetRelatedCircuits,
} from '../api/circuit-dtcs-api'

export const useRelatedDTCs = ({ makeModelId, circuits }) => {
  const {
    status,
    data: dtcs,
    error,
    isFetching,
    isLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    ['circuit-dtcs-by-circuits', makeModelId, ...circuits],
    async () => {
      const result = await apiGetRelatedDTCs({ makeModelId, circuits })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!makeModelId && !!circuits.length,
    },
  )

  return {
    status,
    dtcs,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}

export const useRelatedCircuits = ({ makeModelId, dtcs }) => {
  const {
    status,
    data: circuits,
    error,
    isFetching,
    isLoading,
    isError,
    isIdle,
    isSuccess,
  } = useQuery(
    ['circuit-dtc-by-dtcs', makeModelId, dtcs],
    async () => {
      const result = await apiGetRelatedCircuits({ makeModelId, dtcs })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!makeModelId && !!dtcs.length,
    },
  )

  return {
    status,
    circuits,
    error,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
  }
}
