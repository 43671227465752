import { useState } from 'react'
import { apiSendNotification } from '../api/email-notifications'
import { Button, Flex } from 'antd'

export const SendNotificationButton = ({ intl, action, issue }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const [notificationSentTo, setNotificationSentTo] = useState('')

  const sendNotification = () => {
    return apiSendNotification({
      recipient: action.assignee,
      subject: 'Action Assigned to you',
      issue_id: issue.id,
      issue_description: issue.description,
      issue_action_category: action.type,
      issue_action_description: action.description,
      issue_action_start_date: action.start_date,
      issue_action_due_date: action.due_date,
    }).then(() => {
      setNotificationSentTo(action.assignee)
      toggleConfirmSend()
    })
  }

  const toggleConfirmSend = () => {
    setIsConfirming(!isConfirming)
  }
  if (!issue.id) {
    return ''
  }

  if (isConfirming) {
    return (
      <Flex vertical={false} gap={5}>
        {intl.formatMessage({
          defaultMessage: 'Send Notification to ',
          id: 'incidentMgmt.actionCard.sendNotificationButton.sendNotificationConfirm',
        })}
        {action.assignee}?
        <Button
          className="send-notification-confirm-btn"
          onClick={sendNotification}
          size="small"
          type="primary"
        >
          {intl.formatMessage({
            defaultMessage: 'Yes',
            id: 'incidentMgmt.actionCard.sendNotificationButton.sendYes',
          })}
        </Button>
        <Button
          className="send-notification-confirm-btn"
          onClick={toggleConfirmSend}
          size="small"
        >
          {intl.formatMessage({
            defaultMessage: 'No',
            id: 'incidentMgmt.actionCard.sendNotificationButton.sendNo',
          })}
        </Button>
      </Flex>
    )
  }
  return (
    <Button
      onClick={toggleConfirmSend}
      onKeyDown={toggleConfirmSend}
      disabled={!action.assignee || action.assignee === notificationSentTo}
    >
      {action.assignee !== notificationSentTo &&
        intl.formatMessage({
          defaultMessage: 'Send Notification',
          id: 'incidentMgmt.actionCard.sendNotificationButton.sendNotification',
        })}
      {action.assignee === notificationSentTo &&
        intl.formatMessage({
          defaultMessage: 'Message sent to ',
          id: 'incidentMgmt.actionCard.sendNotificationButton.messageAlreadySent',
        }) + action.assignee}
    </Button>
  )
}
