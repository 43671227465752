import React from 'react'
import { connect } from 'react-redux'
import { unsetModal } from '../../actions/modal-actions'
import ComponentStateModal from './index'
import { addHarnessProblem } from '../../api/controller-api'
import { getDtcCodesFromHighlightedDtcs } from '../../selectors'
import { getElementName } from '../../helpers/element'
import { FormattedMessage } from 'react-intl'

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    harnessLevel: state.modalState.component?.level,
    harness: state.modalState.component?.harness,
    dtcs: getDtcCodesFromHighlightedDtcs(state),
    vin: state.componentState.vin,
    selectedComponent: state.modalState.component,
    faults: state.loggingState.faults.harness,
    title: getElementName(state.modalState.component),
    placeHolder: (
      <FormattedMessage id="tracer.logFailure.typePlaceholderHarness" />
    ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(unsetModal())
    },
    _onSubmit: ({
      annotation,
      vehicleTestResultId,
      makeModelId,
      vin,
      isProblem,
      reason,
      harnessLevel,
      dtcs,
      harness,
    }) => {
      const res = addHarnessProblem({
        annotation,
        vehicleTestResultId,
        harness,
        makeModelId,
        vin,
        isProblem,
        reason,
        harnessLevel,
        dtcs,
      })
      dispatch(unsetModal())
      return res
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, // optional
    ...dispatchProps, // optional
    onSubmit: (args) => {
      return dispatchProps._onSubmit({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
        isProblem: true,
        harnessLevel: stateProps.harnessLevel,
        dtcs: stateProps.dtcs,
        harness: stateProps.harness,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ComponentStateModal)
