import { BLACK_COLOR, WHITE_COLOR } from '../../../constants/colors'
import { DARK_MODE } from '../../../constants/misc'
import { Edge } from '../../reaflow'
import { Label } from './Label'

export const DetailDiagramEdge = ({
  onEdgeClick,
  width = 4,
  theme = null,
  ...props
}) => {
  const colorConvertor = (hex, shouldDarken, percent) => {
    percent = Math.max(0, Math.min(100, percent))

    let r = parseInt(hex.slice(1, 3), 16)
    let g = parseInt(hex.slice(3, 5), 16)
    let b = parseInt(hex.slice(5, 7), 16)

    if (shouldDarken) {
      r = Math.round(r * (1 - percent / 100))
      g = Math.round(g * (1 - percent / 100))
      b = Math.round(b * (1 - percent / 100))
    } else {
      r = Math.round(r + (255 - r) * (percent / 100))
      g = Math.round(g + (255 - g) * (percent / 100))
      b = Math.round(b + (255 - b) * (percent / 100))
    }

    const toHex = (x) => x.toString(16).padStart(2, '0')
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  }

  return (
    <Edge
      {...props}
      interpolation="linear"
      onClick={(event, edge) => {
        onEdgeClick({ event, edge })
      }}
      label={<Label circuit={props.data.circuit} />}
    >
      {
        // Set edge color(s) programmatically.
        // Based on the "use" element.
        // Read more: https://www.w3.org/TR/SVG/struct.html#UseElement
        (event) => {
          const strokes = []
          if (
            !props?.data?.colors ||
            props.data.colors.hex_triplets.length === 0
          ) {
            // Set default edge color.
            return (
              <use
                href={`#${props.properties.id}`}
                strokeWidth={width}
                stroke={theme == DARK_MODE ? '#8295B0' : BLACK_COLOR}
              />
            )
          }

          // Set color(s).
          for (let [index, color] of props.data.colors.hex_triplets.entries()) {
            if (
              props.data.colors.letter_code != '' &&
              color != BLACK_COLOR &&
              color != WHITE_COLOR
            ) {
              color = colorConvertor(
                color,
                color != '#BFBFBF' ? false : true,
                theme == DARK_MODE ? 0 : 40,
              )
            }

            if (props.data.colors.letter_code == '') {
              color = theme == DARK_MODE ? '#8295B0' : BLACK_COLOR
            }
            strokes.push(
              <use
                key={`${props.properties.id}-${color}`}
                href={`#${props.properties.id}`}
                strokeDasharray={`10,${index * 10}`}
                strokeDashoffset={`${index * 10}`}
                stroke={`${color}`}
                strokeWidth={width}
              />,
            )
          }
          return strokes
        }
      }
    </Edge>
  )
}
