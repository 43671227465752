import { Tabs, Button, Collapse, Flex } from 'antd'
import { ProblemDescription } from './ProblemDescription'
import { PossibleRootCauses } from './PossibleRootCauses'
import { DirectRootCauses } from './DirectRootCauses'
import { Actions } from './Actions'
import { useEffect, useState } from 'react'
import { postIssue, updateIssue, linkProblemLogToIssue } from '../api/issues'
import { FaultDetails } from './FaultDetails'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

// This is the left side of the figma (it also occasionally appears on the right).
// https://www.figma.com/proto/ITEOBzG70wVkwRkep3rc0B/Tracer---Ant-Design?page-id=9316%3A23734&node-id=9613-21823&node-type=frame&viewport=-64%2C334%2C0.12&t=KPS4u8Suia3eGyRO-1&scaling=contain&content-scaling=fixed&starting-point-node-id=9607%3A15334
// This has all of the inputs and form functionality for the various parts of the issue
export const IssueEditForm = ({
  issue,
  setIssue,
  fault,
  orientation,
  readOnly,
  showFaultDetails,
  intl,
}) => {
  let history = useHistory()
  const [activeTab, setActiveTab] = useState('problem-desc')

  let items = []
  if (showFaultDetails && !!fault) {
    items.push({
      key: 'fault-details',
      label: intl.formatMessage({
        id: 'incidentMgmt.issueEditForm.faultDetails',
        defaultMessage: 'Fault Details',
      }),
      children: <FaultDetails fault={fault} intl={intl} />,
    })
  }

  items = items.concat([
    {
      key: 'problem-desc',
      label: intl.formatMessage({
        id: 'incidentMgmt.issueEditForm.problemDescription',
        defaultMessage: 'Problem Description',
      }),
      children: (
        <ProblemDescription
          issue={issue}
          setIssue={setIssue}
          readOnly={readOnly}
          intl={intl}
        />
      ),
    },
    {
      key: 'possible-root-causes',
      label: intl.formatMessage({
        id: 'incidentMgmt.issueEditForm.possibleRootCauses',
        defaultMessage: 'Possible Root Causes',
      }),
      children: (
        <PossibleRootCauses
          issue={issue}
          setIssue={setIssue}
          readOnly={readOnly}
          intl={intl}
        />
      ),
    },
    {
      key: 'direct-root-cause',
      label: intl.formatMessage({
        id: 'incidentMgmt.issueEditForm.directRootCauses',
        defaultMessage: 'Direct Root Causes',
      }),
      children: (
        <DirectRootCauses
          issue={issue}
          setIssue={setIssue}
          readOnly={readOnly}
          intl={intl}
        />
      ),
    },
    {
      key: 'actions',
      label: intl.formatMessage({
        id: 'incidentMgmt.issueEditForm.actions',
        defaultMessage: 'Actions',
      }),
      children: (
        <Actions
          issue={issue}
          setIssue={setIssue}
          readOnly={readOnly}
          intl={intl}
        />
      ),
    },
  ])

  const isHorizontal = orientation !== 'vertical'

  const tabKeys = items.map((item) => item.key)

  const advanceTab = (direction) => {
    // direction is +1 or -1, +1 to go right, -1 to go left
    const currentIdx = tabKeys.indexOf(activeTab)
    let newIdx = currentIdx + direction
    if (newIdx >= tabKeys.length) {
      // went past the right tab
      newIdx = newIdx % tabKeys.length
    } else if (newIdx < 0) {
      // went past the left tab
      newIdx = tabKeys.length + newIdx
    }
    const newActiveTab = tabKeys[newIdx]
    setActiveTab(newActiveTab)
  }

  const goToIncidentsPage = () => {
    const url = `/incidents`
    history.push(url)
  }
  // Either post a new issue (if no issue id) or
  // update existing. Also link fault to issue,
  // then redirect to incidents page
  const submitIssue = () => {
    const shouldUpdate = !!issue.id
    let issueReq
    if (shouldUpdate) {
      issueReq = updateIssue(issue.id, issue).then(() => issue.id)
    } else {
      issueReq = postIssue(issue).then((data) => data.issue_id)
    }
    issueReq
      .then((issue_id) => {
        if (!fault.attachedIssue) {
          // only link if it's not already attached
          return linkProblemLogToIssue(issue_id, fault.id)
        }
        return
      })
      .then(() => {
        goToIncidentsPage()
      })
  }

  const getButtons = () => {
    return (
      <Flex id="tab-controls" vertical={false} gap={10}>
        {activeTab !== 'actions' && (
          <Button type="primary" onClick={() => advanceTab(1)}>
            {intl.formatMessage({
              id: 'incidentMgmt.issueEditForm.next',
              defaultMessage: 'Next',
            })}
          </Button>
        )}
        {activeTab === 'actions' && ( // this button actually submits the issue
          <Button
            id="issue-finish-btn"
            type="primary"
            onClick={() => submitIssue()}
          >
            {intl.formatMessage({
              id: 'incidentMgmt.issueEditForm.finish',
              defaultMessage: 'Finish',
            })}
          </Button>
        )}
        {activeTab !== tabKeys[0] && (
          <Button type="default" onClick={() => advanceTab(-1)}>
            {intl.formatMessage({
              id: 'incidentMgmt.issueEditForm.previous',
              defaultMessage: 'Previous',
            })}
          </Button>
        )}
        {activeTab === 'actions' && !readOnly && (
          <Button
            id="issue-cancel-button"
            onClick={() => goToIncidentsPage()}
            danger
          >
            {intl.formatMessage({
              id: 'incidentMgmt.issueEditForm.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
        )}
      </Flex>
    )
  }
  return (
    <Flex
      id="issue-edit-form"
      className={isHorizontal ? 'issue-edit-horizontal' : 'issue-edit-vertical'}
      vertical={true}
      gap={10}
    >
      {isEmpty(issue) && (
        <p className="no-issues">
          {intl.formatMessage({
            id: 'incidentMgmt.issueEditForm.noIssues',
            defaultMessage: 'No Issues',
          })}
        </p>
      )}
      {!isHorizontal && !isEmpty(issue) && (
        <Collapse
          id="issue-details-accordian"
          items={items}
          defaultActiveKey="fault-details"
        />
      )}
      {isHorizontal && !isEmpty(issue) && (
        <Tabs type="card" items={items} activeKey={activeTab} />
      )}
      {isHorizontal && !isEmpty(issue) && getButtons()}
    </Flex>
  )
}
