import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Divider,
  Flex,
  Empty,
  Breadcrumb,
  ConfigProvider,
  theme as antdTheme,
} from 'antd'
import { LeftOutlined, HomeOutlined } from '@ant-design/icons'
import { useLockBodyScroll } from 'react-use'
import { FormattedMessage, injectIntl } from 'react-intl'
import VINSearchInput from '../../components/VINSearchInput'
import {
  useVINSearchInputContext,
  useVINSearchInputDispatch,
} from '../VINSearchInput/VINSearchInputContext'
import { requireAuthentication } from '../../helpers/authentication'
import {
  useVirtualVehicleConfiguration,
  useVirtualVehicleConfigurationDispatch,
} from '../../VirtualVehicleConfiguration/VirtualVehicleConfigurationContext'
import {
  MakeModelSelect,
  SalesCodeSelect,
} from '../../VirtualVehicleConfiguration'
import './style.scss'
import { BRAND_BLUE, WHITE_COLOR } from '../../constants/colors'

const VehicleConfigurationSearch = (props) => {
  const { path, intl } = props
  const { defaultAlgorithm, darkAlgorithm } = antdTheme
  const history = useHistory()
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const virtualVehicleConfigurationDispatch =
    useVirtualVehicleConfigurationDispatch()
  const vinSearchInputContext = useVINSearchInputContext()
  const vinSearchInputDispatch = useVINSearchInputDispatch()

  const [activeKey, setActiveKeys] = React.useState('vin-input')
  let vin

  useLockBodyScroll(true)

  if (vinSearchInputContext.completeVIN) {
    vin = vinSearchInputContext.completeVIN
  } else if (virtualVehicleConfiguration.salesCodesPreSelectedVIN) {
    vin = virtualVehicleConfiguration.salesCodesPreSelectedVIN
  }

  return (
    <>
      <Row className="vehicle-configuration-breadcrumb">
        <div>
          <LeftOutlined
            onClick={() => history.push('/')}
            className="back-button button-disabled"
          />
          <div className="vehicle-configuration-breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <FormattedMessage id="vehicleConfigurationSearch.vehicleConfiguration" />
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </Row>
      <Row className="vehicle-configuration-container">
        <Col span={12} className="vehicle-configuration-left-panel">
          <ConfigProvider
            theme={{
              algorithm: defaultAlgorithm,
              components: {
                Collapse: {
                  headerBg: '#f2f2f2',
                },
                Input: {
                  colorBgContainer: WHITE_COLOR,
                },
              },
            }}
          >
            <VINSearchInput
              disabled={virtualVehicleConfiguration.makeModelID}
              activeKey={activeKey}
              setActiveKeys={setActiveKeys}
            />
          </ConfigProvider>
          <Divider>
            {intl.formatMessage({ id: 'vehicleConfigurationSearch.or' })}
          </Divider>
          <ConfigProvider
            theme={{
              algorithm: defaultAlgorithm,
              components: {
                Collapse: {
                  headerBg: '#f2f2f2',
                },
                Input: {
                  colorBgContainer: WHITE_COLOR,
                },
                Button: {
                  colorBgContainer: WHITE_COLOR,
                  defaultHoverBg: WHITE_COLOR,
                  colorPrimary: BRAND_BLUE,
                  primaryColor: WHITE_COLOR,
                  colorPrimaryHover: '#4096ff',
                },
              },
            }}
          >
            <MakeModelSelect
              required={false}
              disabled={vinSearchInputContext.inputVIN}
              preselectSalesCodes={false}
              activeKey={activeKey}
              setActiveKeys={setActiveKeys}
            />
          </ConfigProvider>
        </Col>
        <Col span={12} className="vehicle-configuration-right-panel">
          <ConfigProvider
            theme={{
              algorithm: defaultAlgorithm,
              components: {
                Button: {
                  colorBgContainer: WHITE_COLOR,
                  defaultHoverBg: WHITE_COLOR,
                  colorPrimary: BRAND_BLUE,
                  primaryColor: WHITE_COLOR,
                  colorPrimaryHover: '#4096ff',
                },
              },
            }}
          >
            {virtualVehicleConfiguration.makeModelID && (
              <SalesCodeSelect
                makeModelID={virtualVehicleConfiguration.makeModelID}
              />
            )}
          </ConfigProvider>
          {!virtualVehicleConfiguration.makeModelID && (
            <Flex
              vertical={true}
              justify="center"
              align="middle"
              className="no-selection"
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={intl.formatMessage({
                  id: 'vehicleConfigurationSearch.noSelection',
                })}
              />
            </Flex>
          )}
        </Col>
      </Row>
      <Row className="vehicle-configuration-footer">
        <Col span={12}>
          <Flex vertical={false} gap="large" align="middle">
            <Button
              type="primary"
              onClick={() => {
                history.push(`/${path}/vin/${vin}`)
              }}
              disabled={!vin}
              data-testid="vin-search-apply-button"
            >
              <FormattedMessage id="vehicleConfigurationSearch.applyButton" />
            </Button>
            <Button
              onClick={() => {
                vinSearchInputDispatch.reset()
                virtualVehicleConfigurationDispatch.reset()
                setActiveKeys('vin-input')
              }}
            >
              <FormattedMessage id="vehicleConfigurationSearch.clearButton" />
            </Button>
          </Flex>
        </Col>
      </Row>
    </>
  )
}

export default requireAuthentication(injectIntl(VehicleConfigurationSearch))
