import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { createDtcList } from '../../selectors'
import {
  getSelectedDtcs,
  getDtcOptions,
  getComponentsList,
} from '../../selectors/repairHistory'
import _ from 'lodash'
import {
  addComponentProblem,
  updateComponentProblem,
} from '../../api/controller-api'
import FaultEditForm from './FaultEditForm'

class ComponentDetails extends Component {
  constructor(props) {
    super(props)
    const isEdit = this.props.item && this.props.item.id
    const isNew = !isEdit
    this.state = {
      isNew,
      isEdit,
      connectorType: 'connector',
      annotation: props.item.annotation,
    }

    const component = this.state.isEdit && {
      label: this.props.item.alias,
      description: this.props.item.description,
      value: this.props.item.component_id,
      id: this.props.item.component_id,
    }
    this.props.initialize({ item: this.props.item, component: component })
  }

  saveItem = (values) => {
    const { id } = values.item
    const dtcSet = new Set(values.item.dtcs)
    const annotation = this.state.annotation
    const reason = values.item.reason.value || values.item.reason
    const componentId = values.component.id
    const dtcs = this.props.dtcOptions
      .filter((dtc) => dtcSet.has(dtc.value))
      .map((dtc) => dtc.dtcCode)
    if (this.state.isNew) {
      this.props.add({
        annotation,
        componentId,
        dtcs,
        reason,
      })
    } else {
      this.props.update({ id, annotation, componentId, dtcs, reason })
    }
  }

  updateAnnotations = (annotation) => {
    this.setState({ annotation })
  }

  isConnectorChecked = () => this.state.connectorType === 'connector'
  isInlineChecked = () => this.state.connectorType === 'inline'

  render = () => (
    <FaultEditForm
      submit={this.props.handleSubmit(this.saveItem)}
      formId={'repair-history-component-details'}
      intl={this.props.intl}
      faults={this.props.faults}
      dtcOptions={this.props.dtcOptions}
      selectedDtcs={this.props.selectedDtcs}
      onClose={this.props.onClose}
      annotations={this.state.annotation}
      updateAnnotation={this.updateAnnotations}
      categorySelectorDetails={{
        label: 'repairHistory.add.form.componentLabel',
        name: 'component',
        data: this.props.components,
        placeholder: 'repairHistory.add.form.componentPlaceholder',
      }}
    />
  )
}
ComponentDetails = reduxForm({
  form: 'repair-history-component-details',
  destroyOnUnmount: true,
})(ComponentDetails)

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: _.get(
      ownProps,
      'item.vehicle_test_result_id',
      state.componentState.vehicleTestResultId,
    ),
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    dtcOptions: getDtcOptions(state),
    selectedDtcs: getSelectedDtcs(_.get(ownProps, 'item.dtcs', []))(state),
    faults: state.loggingState.faults.component.map((item) => ({
      value: item.key,
      label: item.key,
    })),
    components: getComponentsList(state),
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _add: ({
    annotation,
    vehicleTestResultId,
    componentId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    addComponentProblem({
      annotation,
      vehicleTestResultId,
      componentId,
      dtcs,
      isProblem: true,
      makeModelId,
      reason,
      vin,
    })
  },
  _update: ({
    id,
    annotation,
    vehicleTestResultId,
    componentId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    updateComponentProblem({
      id,
      annotation,
      vehicleTestResultId,
      componentId,
      makeModelId,
      vin,
      isProblem: true,
      reason,
      dtcs,
    })
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    add: (args) => {
      dispatchProps._add({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
    update: (args) => {
      dispatchProps._update({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
  }
}

export const ComponentDetailsContainer = injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ComponentDetails),
)
