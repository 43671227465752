import { useUserPlant } from '../hooks/User'
import { useDispatch } from 'react-redux'
import { MODAL_SELECT_PLANT } from '../constants'
import { setModal } from '../actions/modal-actions'
import { Button } from 'antd'

export const PlantButton = ({ intl, onPlantSwitch }) => {
  const [userPlant] = useUserPlant()
  const dispatch = useDispatch()
  const plantLabel = intl.formatMessage({ id: 'faultReport.plantLabel' })
  return (
    <div className="plant">
      {plantLabel}:&nbsp;
      <Button
        type="text"
        onClick={(event) => {
          event.stopPropagation()
          dispatch(setModal(MODAL_SELECT_PLANT))
          if (onPlantSwitch) {
            onPlantSwitch()
          }
        }}
      >
        {userPlant}
      </Button>
    </div>
  )
}
