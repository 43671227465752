import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { createDtcList } from '../../selectors'
import { getSelectedDtcs, getDtcOptions } from '../../selectors/repairHistory'
import { addOtherProblem, updateOtherProblem } from '../../api/controller-api'
import FaultEditForm from './FaultEditForm'

/*
 Helper FSCs
*/

class OtherDetails extends Component {
  constructor(props) {
    super(props)
    const isNew = !(this.props.item && this.props.item.id)
    this.state = {
      isNew,
      connectorType: 'other',
      annotation: props.item.annotation,
    }
    this.props.initialize({ item: this.props.item })
  }
  saveItem = (values) => {
    const { id } = values.item
    const dtcSet = new Set(values.item.dtcs)
    const annotation = this.state.annotation
    const reason = values.item.reason.value || values.item.reason
    const dtcs = this.props.dtcOptions
      .filter((dtc) => dtcSet.has(dtc.value))
      .map((dtc) => dtc.dtcCode)
    if (this.state.isNew) {
      this.props.add({
        annotation,
        dtcs,
        reason,
      })
    } else {
      this.props.update({ id, annotation, dtcs, reason })
    }
  }

  updateAnnotations = (annotation) => {
    this.setState({ annotation })
  }

  render = () => (
    <FaultEditForm
      submit={this.props.handleSubmit(this.saveItem)}
      formId={'repair-history-other-details'}
      intl={this.props.intl}
      faults={this.props.faults}
      dtcOptions={this.props.dtcOptions}
      selectedDtcs={this.props.selectedDtcs}
      onClose={this.props.onClose}
      annotations={this.state.annotation}
      updateAnnotation={this.updateAnnotations}
    />
  )
}
OtherDetails = reduxForm({
  form: 'repair-history-other-details',
  destroyOnUnmount: true,
})(OtherDetails)

const mapStateToProps = (state, ownProps) => {
  return {
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    dtcOptions: getDtcOptions(state),
    selectedDtcs: getSelectedDtcs(_.get(ownProps, 'item.dtcs', []))(state),
    faults: state.loggingState.faults.other.map((item) => ({
      value: item.key,
      label: item.key,
    })),
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _add: ({
    annotation,
    vehicleTestResultId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    addOtherProblem({
      annotation,
      vehicleTestResultId,
      dtcs,
      isProblem: true,
      makeModelId,
      reason,
      vin,
    })
  },
  _update: ({
    id,
    annotation,
    vehicleTestResultId,
    dtcs,
    makeModelId,
    reason,
    vin,
  }) => {
    updateOtherProblem({
      id,
      annotation,
      vehicleTestResultId,
      makeModelId,
      vin,
      isProblem: true,
      reason,
      dtcs,
    })
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    add: (args) => {
      dispatchProps._add({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
    update: (args) => {
      dispatchProps._update({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
      })
      ownProps.onClose()
    },
  }
}

export const OtherDetailsContainer = injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(OtherDetails),
)
