import { DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import objectSupport from 'dayjs/plugin/objectSupport'
import { useEffect, useState } from 'react'
import { useGetIssues } from '../hooks/Issues'
import { useGetUsers } from '../hooks/User'
import { isEmpty } from 'lodash'
import { useCheckPermission } from '../hooks/Permissions'
import { Card, Button, Flex } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { SendNotificationButton } from './NotificationButton'

dayjs.extend(objectSupport)
dayjs.extend(utc)

// actions look like
/* {
  assignee: null,
  type: null,
  description: null,
  start_date: null,
  due_date: null,
  close_date: null,
  status: <open or closed>
}*/

const ActionDeleteButton = ({ removeAction, canEditActions, intl }) => {
  const [isConfirming, setIsConfirming] = useState(false)

  const toggleConfirmDeletion = () => {
    setIsConfirming(!isConfirming)
  }

  if (!canEditActions) {
    return ''
  }
  if (isConfirming) {
    return (
      <Flex vertical={false} gap={5}>
        {intl.formatMessage({
          defaultMessage: 'Delete Action?',
          id: 'incidentMgmt.actionCard.actionDeleteButton.deleteAction',
        })}
        <Button
          className="action-close-confirm-btn"
          onClick={removeAction}
          size="small"
          danger
        >
          {intl.formatMessage({
            defaultMessage: 'Yes',
            id: 'incidentMgmt.actionCard.actionDeleteButton.deleteYes',
          })}
        </Button>
        <Button
          className="action-close-confirm-btn"
          onClick={toggleConfirmDeletion}
          size="small"
        >
          {intl.formatMessage({
            defaultMessage: 'No',
            id: 'incidentMgmt.actionCard.actionDeleteButton.deleteNo',
          })}
        </Button>
      </Flex>
    )
  }
  return (
    <Button
      className="action-close-btn"
      onClick={toggleConfirmDeletion}
      size="small"
    >
      <DeleteOutlined />
    </Button>
  )
}

export const ActionCard = ({
  action,
  idx,
  updateAction,
  readOnly,
  intl,
  issue,
}) => {
  const [options, setOptions] = useState({
    type: [],
    description: [],
  })
  const { issues } = useGetIssues()

  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = {
      type: [],
      description: [],
    }
    issues.forEach((issue) => {
      const actions = issue.actions
      if (actions) {
        actions.forEach((act) => {
          if (act['type']) {
            opts.type.push(act['type'])
          }
          if (act['description']) {
            opts.description.push(act['description'])
          }
        })
      }
    })
    Object.keys(opts).forEach((key) => {
      const allOptions = [...new Set(opts[key])]
      opts[key] = allOptions
        .filter((opt) => !!opt)
        .map((opt) => {
          return {
            label: opt,
            value: opt,
          }
        })
    })
    setOptions(opts)
  }, [issues])

  const { users } = useGetUsers()
  const [userOpts, setUserOpts] = useState([])
  useEffect(() => {
    if (isEmpty(users)) {
      setUserOpts([])
      return
    }
    setUserOpts(
      users
        .filter((user) => {
          return !user.disabled
        })
        .map((user) => {
          return {
            label: user.email,
            value: user.email,
          }
        }),
    )
  }, [users])

  const checkPermission = useCheckPermission()
  const [canEditActions, setCanEditActions] = useState(true)

  useEffect(() => {
    setCanEditActions(checkPermission('issues-actions:edit') && !readOnly)
  }, [checkPermission, readOnly])

  const todayStart = dayjs().startOf('day')

  const removeAction = () => {
    updateAction(null, idx)
  }

  const updateStatus = (value) => {
    action['status'] = value
    if (value === 'closed') {
      action.close_date = todayStart.local().toISOString()
    } else if (value === 'open') {
      action.close_date = null
    }
    updateAction(action, idx)
  }

  const statusOptions = [
    {
      label: intl.formatMessage({
        id: 'incidentMgmt.actionCard.optionOpen',
        defaultMessage: 'open',
      }),
      value: 'open',
    },
    {
      label: intl.formatMessage({
        id: 'incidentMgmt.actionCard.optionClosed',
        defaultMessage: 'closed',
      }),
      value: 'closed',
    },
  ]

  const updateAssignee = (values) => {
    const value = values[0]
    action['assignee'] = value
    updateAction(action, idx)
  }

  const updateDescription = (value) => {
    action['description'] = value[0]
    updateAction(action, idx)
  }

  const updateType = (value) => {
    action['type'] = value[0]
    updateAction(action, idx)
  }

  const updateStartDate = (value) => {
    action['start_date'] = value.toISOString()
    updateAction(action, idx)
  }

  const updateDueDate = (value) => {
    action['due_date'] = value.toISOString()
    updateAction(action, idx)
  }

  const updateCloseDate = (value) => {
    action['close_date'] = value.toISOString()
    updateAction(action, idx)
  }

  const getDates = () => {
    const dates = [null, null, null]
    if (!!action.start_date) {
      dates[0] = dayjs(action.start_date)
    }
    if (!!action.due_date) {
      dates[1] = dayjs(action.due_date)
    }
    if (!!action.close_date) {
      dates[2] = dayjs(action.close_date)
    }
    return dates
  }

  const searchPlaceholder = intl.formatMessage({
    id: 'incidentMgmt.searchPlaceholder',
    defaultMessage: 'Enter or select similar',
  })

  const cardHeader = () => (
    <span className="action-title">
      {intl.formatMessage({
        id: 'incidentMgmt.actionCard.cardTitle',
        defaultMessage: 'Action',
      })}{' '}
      {idx + 1}
    </span>
  )

  return (
    <Card
      size="small"
      title={cardHeader()}
      extra={ActionDeleteButton({ removeAction, canEditActions, intl })}
    >
      <Flex vertical={true} gap={10}>
        <div className="action-input-group description-input-group input-and-label">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.type',
              defaultMessage: 'Type',
            })}
          </span>
          <Select
            mode="tags"
            placeholder={readOnly ? '' : searchPlaceholder}
            key="type"
            disabled={readOnly}
            className="type-input"
            value={action.type ? [action.type] : []}
            options={options.type}
            onChange={(value) => updateType(value)}
            maxCount="1"
            style={{ width: '100%' }}
          />
        </div>
        <div className="action-input-group description-input-group input-and-label">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.description',
              defaultMessage: 'Description',
            })}
          </span>
          <Select
            mode="tags"
            placeholder={readOnly ? '' : searchPlaceholder}
            key="description"
            disabled={readOnly}
            className="description-input"
            value={action.description ? [action.description] : []}
            options={options.description}
            onChange={(value) => updateDescription(value)}
            maxCount="1"
            style={{ width: '100%' }}
          />
        </div>
        <div className="action-input-group date-input-group">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.startDate',
              defaultMessage: 'Start Date',
            })}
          </span>
          <DatePicker
            disabled={readOnly}
            allowClear={false}
            onChange={(value) => updateStartDate(value)}
            presets={[
              {
                label: intl.formatMessage({
                  id: 'incidentMgmt.actionCard.today',
                  defaultMessage: 'Today',
                }),
                value: todayStart.local(),
              },
            ]}
            value={getDates()[0]}
          />
        </div>
        <div className="action-input-group date-input-group">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.dueDate',
              defaultMessage: 'Due Date',
            })}
          </span>
          <DatePicker
            disabled={readOnly}
            allowClear={false}
            onChange={(value) => updateDueDate(value)}
            presets={[
              {
                label: intl.formatMessage({
                  id: 'incidentMgmt.actionCard.today',
                  defaultMessage: 'Today',
                }),
                value: todayStart.local(),
              },
            ]}
            value={getDates()[1]}
          />
        </div>
        <Flex
          vertical={true}
          gap={5}
          className="action-input-group assignee-input-group input-and-label"
        >
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.assignee',
              defaultMessage: 'Assignee',
            })}
          </span>
          <Select
            key="assignee"
            className="assignee-input"
            value={action.assignee ? [action.assignee] : []}
            onChange={(values) => updateAssignee(values)}
            disabled={readOnly}
            mode="tags"
            maxCount="1"
            options={userOpts}
            style={{ width: '100%' }}
          />
          <SendNotificationButton intl={intl} action={action} issue={issue} />
        </Flex>
        <div className="action-input-group status-input-group">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.status',
              defaultMessage: 'Status',
            })}
          </span>
          <Select
            key="status"
            className="status-input"
            value={action.status || ''}
            onChange={(value) => updateStatus(value)}
            disabled={!canEditActions}
            options={statusOptions}
            style={{ width: '100%' }}
          />
        </div>
        <div className="action-input-group date-input-group">
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.actionCard.closedDate',
              defaultMessage: 'Closed Date',
            })}
          </span>
          <DatePicker
            disabled={!canEditActions || action.status === 'open'}
            allowClear={false}
            onChange={(value) => updateCloseDate(value)}
            presets={[
              {
                label: intl.formatMessage({
                  id: 'incidentMgmt.actionCard.today',
                  defaultMessage: 'Today',
                }),
                value: todayStart.local(),
              },
            ]}
            value={getDates()[2]}
          />
        </div>
      </Flex>
    </Card>
  )
}
