// this is the man, machine, method, material, design component
import { useEffect, useState } from 'react'
import { Switch, Select, Flex } from 'antd'
import { useGetIssues } from '../hooks/Issues'

const emptyPossibleRootCauses = {
  man: null,
  machine: null,
  method: null,
  material: null,
  design: null,
}

const getPossibleRootCauses = (issue) => {
  if (!!issue && !!issue.possible_root_causes) {
    return issue.possible_root_causes
  }
  return structuredClone(emptyPossibleRootCauses)
}

export const PossibleRootCauses = ({ issue, setIssue, readOnly, intl }) => {
  const [possibleRootCauses, setPossibleRootCauses] = useState(
    getPossibleRootCauses(issue),
  )
  const [inputEnabled, setInputEnabled] = useState({
    man: false,
    machine: false,
    method: false,
    material: false,
    design: false,
  })
  const [options, setOptions] = useState({
    man: [],
    machine: [],
    method: [],
    material: [],
    design: [],
  })
  const { issues } = useGetIssues()

  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = {
      man: [],
      machine: [],
      method: [],
      material: [],
      design: [],
    }
    issues.forEach((iss) => {
      const prc = iss['possible_root_causes']
      if (prc) {
        Object.keys(opts).forEach((key) => {
          opts[key].push(prc[key])
        })
      }
    })
    Object.keys(opts).forEach((key) => {
      const allOptions = [...new Set(opts[key])]
      opts[key] = allOptions
        .filter((opt) => !!opt)
        .map((opt) => {
          return {
            label: opt,
            value: opt,
          }
        })
    })
    setOptions(opts)
  }, [issues])

  useEffect(() => {
    setPossibleRootCauses(getPossibleRootCauses(issue))
  }, [issue])

  const [isPRCEmpty, setIsPrcEmpty] = useState(true)
  useEffect(() => {
    setIsPrcEmpty(
      Object.keys(possibleRootCauses).every((prcKey) => {
        return possibleRootCauses[prcKey] === null
      }),
    )
    const newInputEnabled = structuredClone(inputEnabled)
    Object.keys(possibleRootCauses).forEach((key) => {
      newInputEnabled[key] = possibleRootCauses[key] !== null
    })
    setInputEnabled(newInputEnabled)
    console.log('possible root causes update')
    console.log(possibleRootCauses)
  }, [possibleRootCauses])

  const orderedOptions = ['man', 'machine', 'method', 'material', 'design']

  const updateIssuePossibleRootCauses = (key, value) => {
    const newPossibleRootCauses = structuredClone(possibleRootCauses)
    newPossibleRootCauses[key] = value[0]
    setPossibleRootCauses(newPossibleRootCauses)
    let newIssue = {
      ...issue,
      ...{ possible_root_causes: newPossibleRootCauses },
    }
    setIssue(newIssue)
  }

  const toggleInputEnabled = (key) => {
    const newInputs = structuredClone(inputEnabled)
    newInputs[key] = !newInputs[key]
    if (newInputs[key] && possibleRootCauses[key] === null) {
      updateIssuePossibleRootCauses(key, [''])
    }
    if (!newInputs[key]) {
      updateIssuePossibleRootCauses(key, [null])
    }
    setInputEnabled(newInputs)
  }

  return (
    <Flex id="issue-possible-root-causes" vertical={true} gap={10}>
      {orderedOptions
        .filter((key) => {
          return !readOnly || possibleRootCauses[key] !== null
        })
        .map((key) => {
          return (
            <div
              className="input-and-label"
              key={`possible-root-causes-${key}`}
            >
              <Flex className="input-label-flex" vertical={false} gap={5}>
                {!readOnly && (
                  <Switch
                    onClick={() => toggleInputEnabled(key)}
                    checked={inputEnabled[key]}
                    disabled={readOnly}
                  />
                )}
                {intl.formatMessage({
                  id: `incidentMgmt.possibleRootCauses.${key}`,
                  defaultMessage: key,
                })}
              </Flex>
              <Select
                mode="tags"
                placeholder={
                  readOnly
                    ? ''
                    : intl.formatMessage({
                        id: 'incidentMgmt.searchPlaceholder',
                        defaultMessage: 'Enter or Select Similar',
                      })
                }
                value={
                  !!possibleRootCauses[key] ? [possibleRootCauses[key]] : []
                }
                disabled={readOnly || !inputEnabled[key]}
                options={options[key]}
                onChange={(value) => updateIssuePossibleRootCauses(key, value)}
                maxCount="1"
                style={{ width: '100%' }}
              />
            </div>
          )
        })}
      {isPRCEmpty && readOnly && (
        <span class="empty-prc-notice">
          {intl.formatMessage({
            id: 'incidentMgmt.possibleRootCauses.noRootCausesEntered',
            defaultMessage: 'No possible root causes entered',
          })}
        </span>
      )}
    </Flex>
  )
}
