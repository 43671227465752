import React, { useState } from 'react'
import { Button, Collapse, Flex, Input } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMakeModels } from '../hooks/MakeModels'
import { useSalesCodeConstraints } from '../hooks/SalesCodes'

import {
  useVirtualVehicleConfiguration,
  useVirtualVehicleConfigurationDispatch,
} from './VirtualVehicleConfigurationContext'
import { useSalesCodesToFetchVIN } from '../hooks/VirtualVehicleConfiguration'
import './style.scss'
import Loading, { Loadable } from '../components/Loading'

export const MakeModelSelect = injectIntl(
  ({
    intl,
    required = true,
    disabled = false,
    preselectSalesCodes = true,
    activeKey,
    setActiveKeys,
  }) => {
    const { isSuccess, makeModels } = useMakeModels()
    const { selectedMakeDesc, selectedModelId, makeSearch, modelSearch } =
      useVirtualVehicleConfiguration()
    const dispatch = useVirtualVehicleConfigurationDispatch()
    const [makeDescriptions, setMakeDescriptions] = React.useState([])
    const [modelDescriptions, setModelDescriptions] = React.useState([])

    React.useEffect(() => {
      if (makeModels) {
        const descriptions = [
          ...new Set(
            makeModels
              .filter((makeModel) => makeModel.make_description !== null)
              .map((mm) => mm.make_description),
          ),
        ].sort((a, b) => a.localeCompare(b))
        setMakeDescriptions(descriptions)
      }
      if (!preselectSalesCodes) return
      if (isSuccess) {
        dispatch.handleMakeModelIDSelection({ makeModelID: makeModels[0].id })
      }
    }, [makeModels])

    const updateModelDescriptions = (selectedMakeDescription) => {
      const descriptions = makeModels
        .filter((mm) => mm.make_description === selectedMakeDescription)
        .map((mm) => {
          return {
            id: mm.id,
            description: mm.model_description,
          }
        })
        .sort((mm1, mm2) => {
          const desc1 = mm1.description.toLowerCase()
          const desc2 = mm2.description.toLowerCase()
          if (desc1 < desc2) {
            return -1
          }
          if (desc1 > desc2) {
            return 1
          }
          return 0
        })
      dispatch.updateSelectedModelId({ selectedModelId: null })
      dispatch.handleMakeModelIDSelection({ makeModelID: null })
      setModelDescriptions(descriptions)
      dispatch.updateSelectedMakeDesc({
        selectedMakeDesc: selectedMakeDescription,
      })
      setActiveKeys('model-buttons-container')
    }

    const createMakeButtonList = () => {
      const filteredResult =
        makeSearch == ''
          ? makeDescriptions
          : makeDescriptions.filter((item) =>
              item.toLocaleLowerCase().includes(makeSearch.toLocaleLowerCase()),
            )
      return filteredResult.map((desc, idx) => {
        return (
          <Button
            onClick={() =>
              selectedMakeDesc != desc && updateModelDescriptions(desc)
            }
            type={selectedMakeDesc === desc ? 'primary' : 'default'}
            key={`mk-btn-${idx}`}
          >
            {desc}
          </Button>
        )
      })
    }

    const createModelButtonList = () => {
      const filteredResult =
        modelSearch == ''
          ? modelDescriptions
          : modelDescriptions.filter((item) =>
              item.description
                .toLocaleLowerCase()
                .substring(modelSearch.toLocaleLowerCase()),
            )
      return filteredResult.map((mdlDesc, idx) => {
        return (
          <Button
            onClick={() => {
              dispatch.updateSelectedModelId({ selectedModelId: mdlDesc.id })
              dispatch.handleMakeModelIDSelection({ makeModelID: mdlDesc.id })
              setActiveKeys('sales-code-buttons-container')
            }}
            type={selectedModelId === mdlDesc.id ? 'primary' : 'default'}
            key={`mdl-btn-${idx}`}
          >
            {mdlDesc.description}
          </Button>
        )
      })
    }

    const onChangeHandler = (item) => {
      setActiveKeys(item[0])
    }

    const onSearchHandler = (event) => {
      const searchId = event.target.id
      const value = event.target.value

      switch (searchId) {
        case 'make-search':
          dispatch.updateMakeSearch({ makeSearch: value })
          break
        case 'model-search':
          dispatch.updateModelSearch({ modelSearch: value })
          break
      }
    }

    return (
      isSuccess && (
        <div id="make-model-select-container">
          <Collapse
            onChange={onChangeHandler}
            accordion
            collapsible="header"
            activeKey={activeKey}
            items={[
              {
                key: 'make-buttons-container',
                label: (
                  <Flex
                    vertical={false}
                    justify="space-between"
                    align="middle"
                    data-testid="select-make-header"
                  >
                    <span>
                      <FormattedMessage id="virtualVehicleConfiguration.selectMake" />
                    </span>
                    {activeKey == 'make-buttons-container' && (
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'virtualVehicleConfiguration.enterKeyword',
                        })}
                        size="small"
                        className="vehicle-configuration-collapse-search"
                        id="make-search"
                        onChange={onSearchHandler}
                        onClick={(event) => event.stopPropagation()}
                        value={makeSearch}
                      />
                    )}
                  </Flex>
                ),
                children: (
                  <Flex wrap vertical={false} gap="small">
                    {createMakeButtonList()}
                  </Flex>
                ),
              },
              {
                key: 'model-buttons-container',
                label: (
                  <Flex
                    vertical={false}
                    justify="space-between"
                    align="middle"
                    data-testid="select-model-header"
                  >
                    <span>
                      <FormattedMessage id="virtualVehicleConfiguration.selectModel" />
                    </span>
                    {activeKey == 'model-buttons-container' && (
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'virtualVehicleConfiguration.enterKeyword',
                        })}
                        size="small"
                        className="vehicle-configuration-collapse-search"
                        id="model-search"
                        onChange={onSearchHandler}
                        onClick={(event) => event.stopPropagation()}
                        value={modelSearch}
                      />
                    )}
                  </Flex>
                ),
                children: (
                  <Flex wrap vertical={false} gap="small">
                    {createModelButtonList()}
                  </Flex>
                ),
                collapsible: selectedMakeDesc !== '' ? 'header' : 'disabled',
              },
            ]}
          ></Collapse>
        </div>
      )
    )
  },
)

function groupBySalesCodeGroup(salesCodes) {
  return salesCodes.reduce((acc, obj) => {
    const key = obj.group.name
    const curGroup = acc[key] ?? []

    return { ...acc, [key]: [...curGroup, obj] }
  }, {})
}

const SalesCodeSelectForm = ({ salesCodeConstraints, searchFilter }) => {
  const dispatch = useVirtualVehicleConfigurationDispatch()
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const salesCodeGroups = groupBySalesCodeGroup(salesCodeConstraints)

  React.useEffect(() => {
    if (salesCodeConstraints) {
      generateInitialSalesCodesData()
    }
  }, [salesCodeConstraints])

  const generateInitialSalesCodesData = () => {
    const salesCodesData = {}
    Object.entries(salesCodeGroups).forEach(([group, constraints], index) => {
      salesCodesData[group] = null
    })

    dispatch.handleInitialSalesCodes({
      salesCodesSelection: salesCodesData,
      selectedSalesCodeDescription: salesCodesData,
    })
  }

  return (
    <>
      {Object.entries(salesCodeGroups).map(([group, constraints], index) => {
        const groupLabel = constraints[0].group.description || group

        return (
          <Flex
            vertical={true}
            gap="small"
            className="virtual-vehicle-configuration-sales-codes-group"
            key={`${virtualVehicleConfiguration.makeModelID}-${group}`}
          >
            <div className="virtual-vehicle-configuration-sales-codes-group-label">
              {groupLabel}
            </div>
            <Flex
              wrap
              vertical={false}
              gap="small"
              className="virtual-vehicle-configuration-sales-codes-group-options"
            >
              {constraints.map((constraint) => {
                const key = `${virtualVehicleConfiguration.makeModelID}-${group}-${constraint.sales_code.name}`
                const salesCodeLabel =
                  constraint.sales_code.description ||
                  constraint.sales_code.name
                return (
                  <Button
                    type={
                      virtualVehicleConfiguration.salesCodesSelection[group] ==
                      constraint.sales_code.name
                        ? 'primary'
                        : 'default'
                    }
                    id={constraint.sales_code.name}
                    onClick={() => {
                      const value = constraint.sales_code.name
                      if (
                        virtualVehicleConfiguration.salesCodesSelection[
                          group
                        ] == value
                      ) {
                        dispatch.handleSalesCodeDelete({ group })
                        const salesCodesSelection = {
                          ...virtualVehicleConfiguration.salesCodesSelection,
                        }
                        delete salesCodesSelection[group]
                        const result = Object.values(
                          salesCodesSelection,
                        ).filter((element) => element)
                        if (result.length == 0) {
                          dispatch.updateSalesCodesPreSelectedVIN({
                            salesCodesPreSelectedVIN: null,
                          })
                        }
                      } else {
                        dispatch.handleSalesCodeAdded({
                          group,
                          salesCode: value,
                        })
                      }
                    }}
                  >
                    {salesCodeLabel}
                  </Button>
                )
              })}
            </Flex>
          </Flex>
        )
      })}
    </>
  )
}

export const SalesCodeSelect = ({ makeModelID }) => {
  const [salesCodesString, updateSalesCodesString] = useState('')
  const { isSuccess, isLoading, salesCodeConstraints } =
    useSalesCodeConstraints({ makeModelID })
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const dispatch = useVirtualVehicleConfigurationDispatch()
  const { isVinLoading, results } = useSalesCodesToFetchVIN({
    salesCodesString: salesCodesString,
    makeModelID: makeModelID,
  })

  React.useEffect(() => {
    if (
      Object.keys(virtualVehicleConfiguration.salesCodesSelection).length != 0
    ) {
      prepareSalesCodesString()
    } else {
      updateSalesCodesString('')
    }
  }, [virtualVehicleConfiguration.salesCodesSelection])

  const prepareSalesCodesString = () => {
    const salesCodes = virtualVehicleConfiguration.salesCodesSelection
    updateSalesCodesString(
      Object.values(salesCodes).filter((value) => value != null),
    )
  }

  React.useEffect(() => {
    dispatch.toggleVINLoading(isLoading)
    if (results) {
      updateSalesCodesSelection()
    }
  }, [results, isLoading])

  const updateSalesCodesSelection = () => {
    const currentlySelectedSalesCodes = {
      ...virtualVehicleConfiguration.salesCodesSelection,
    }

    if (!results.selectAllSalesCodes) {
      if (results.vin.length > 0) {
        let lastSelectedSalesCode = ''

        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          if (value != null) {
            lastSelectedSalesCode = key
          }
        })

        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          if (lastSelectedSalesCode != key) {
            currentlySelectedSalesCodes[key] = null
          }
        })
      } else {
        Object.entries(currentlySelectedSalesCodes).map(([key, value]) => {
          currentlySelectedSalesCodes[key] = null
        })
      }
    }

    dispatch.handleInitialSalesCodes({
      salesCodesSelection: currentlySelectedSalesCodes,
    })
    dispatch.updateSalesCodesPreSelectedVIN({
      salesCodesPreSelectedVIN: results.vin,
    })
  }

  return (
    <Loadable isLoading={isLoading} showText={false}>
      <Flex
        vertical={true}
        gap="middle"
        className="virtual-vehicle-configuration-sales-code-select-form-container"
      >
        {isVinLoading && (
          <div id="virtual-vehicle-configuration-sales-code-loading">
            <Loading showText={false} />
          </div>
        )}
        <SalesCodeSelectForm salesCodeConstraints={salesCodeConstraints} />
      </Flex>
    </Loadable>
  )
}
