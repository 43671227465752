import React from 'react'
import { Popover } from 'antd'
import { FormattedMessage } from 'react-intl'
import { func, string } from 'prop-types'
import { Tooltip } from 'antd'
import { login } from '../../api/auth-api'
import { Button } from 'antd'

import { UserOutlined, DownOutlined } from '@ant-design/icons'

const MAX_USERNAME_DISPLAY_LEN = 28

function Collapsible(props) {
  const { onClick, username, clientId, isMenuOpen, toggleMenu } = props
  const [displayName, setDisplayName] = React.useState('')
  const [fullUsername, setFullUsername] = React.useState('')

  React.useEffect(() => {
    if (!username || !clientId) {
      return
    }
    let fullname, dispName
    if (!!username) {
      fullname = username
    } else {
      fullname = clientId
    }
    if (fullname.length > MAX_USERNAME_DISPLAY_LEN) {
      dispName = `${fullname.slice(0, MAX_USERNAME_DISPLAY_LEN)}...`
    } else {
      dispName = fullname
    }
    setDisplayName(dispName)
    setFullUsername(fullname)
  }, [username, clientId])

  const toggleUserMenu = (e) => {
    onClick(e)
    toggleMenu(!isMenuOpen)
  }

  return (
    <div data-testid="collapsible" className="collapsible">
      <div className="username">
        {fullUsername === displayName && <span>{displayName}</span>}
        {fullUsername !== displayName && (
          <Tooltip title={fullUsername} zIndex="9999" placement="left">
            <span>{displayName}</span>
          </Tooltip>
        )}
      </div>
      <Button
        onClick={toggleUserMenu}
        className="user-menu-button"
        data-testid="usermenu-button"
      >
        <UserOutlined />
        <DownOutlined data-testid="toggle-icon" />
      </Button>
    </div>
  )
}

function ClickableItem({ onClick, messageKey, defaultLabel, testId }) {
  return (
    <div style={{ padding: '10px' }}>
      <Button type="text" onClick={onClick} data-testid={testId || null}>
        <FormattedMessage
          id={messageKey}
          defaultMessage={defaultLabel || null}
        />
      </Button>
    </div>
  )
}

function Content(props) {
  const {
    onChangeStartView,
    openFeedbackModal,
    onLogout,
    tracerVersion,
    clientId,
    userIsSignedIn,
    openSelectPlantModal,
    openSelectStationAlertModal,
    toggleMenu,
  } = props
  return (
    <div className="content" id="user-menu-popover">
      {userIsSignedIn ? (
        clientId && (
          <>
            <div className="content-row username">{clientId}</div>
            <div data-testid="separator" className="separator" />
          </>
        )
      ) : (
        <Button onClick={() => login()} size="small" variant="text">
          <FormattedMessage id="auth.signin" defaultMessage="Sign In" />
        </Button>
      )}
      <div style={{ margin: 0, padding: '10px 20px' }}>
        <ClickableItem
          onClick={() => {
            toggleMenu(false)
            onChangeStartView()
          }}
          messageKey="userMenu.changeStartView"
          defaultLabel="Change your start view"
          testId="change-start-view"
        />
        <ClickableItem
          onClick={() => {
            toggleMenu(false)
            openFeedbackModal()
          }}
          messageKey="userMenu.feedback"
          defaultLabel="Feedback"
          testId="feedback"
        />
        <ClickableItem
          onClick={() => {
            toggleMenu(false)
            openSelectPlantModal()
          }}
          messageKey="userMenu.selectPlant"
          defaultLabel="Select Plant"
          testId="select-plant"
        />
        <ClickableItem
          onClick={() => {
            toggleMenu(false)
            openSelectStationAlertModal()
          }}
          messageKey="userMenu.selectStationAlert"
          defaultLabel="Select Report Filter"
          testId="select-station-alert"
        />
        {userIsSignedIn && (
          <ClickableItem
            onClick={onLogout}
            messageKey="auth.logout"
            defaultLabel="Logout"
            testId="logout-button"
          />
        )}
      </div>
      <div data-testid="separator" className="separator" />
      <div data-testid="tracer-version" className="content-row tracer-version">
        <FormattedMessage
          id="userMenu.tracerVersionLabel"
          defaultMessage="Tracer Version"
        />
        {':'} {tracerVersion}
      </div>
    </div>
  )
}

function UserMenu(props) {
  const { className, username, clientId, ...remainingProps } = props
  const [isMenuOpen, toggleMenu] = React.useState(false)
  return (
    <div className={className} data-testid="user-menu">
      <Popover
        trigger="click"
        placement="bottom"
        open={isMenuOpen}
        content={() => (
          <Content
            clientId={clientId}
            {...remainingProps}
            toggleMenu={toggleMenu}
          />
        )}
        onOpenChange={toggleMenu}
      >
        <Collapsible
          username={username}
          clientId={clientId}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
        />
      </Popover>
    </div>
  )
}

UserMenu.propTypes = {
  username: string,
  clientId: string,
  tracerVersion: string.isRequired,
  onLogout: func.isRequired,
}
UserMenu.defaultProps = {
  username: null,
}

UserMenu.defaultProps = {
  username: '',
}

export default UserMenu
