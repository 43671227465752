import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import VehicleTestsTable from './VehicleTestsTable'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'
import ModalFullScreen from './Modal/ModalFullScreen'

class SwitchTest extends Component {
  constructor(props, context) {
    super(props, context)
    this.closeModal = this.closeModal.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderContent = this.renderContent.bind(this)
  }

  componentDidMount() {
    if (this.props.vin) {
      this.props.searchForVin(this.props.vin)
    }
  }

  componentDidUpdate(prevProps) {
    const prevVin = prevProps.vin
    const thisVin = this.props.vin
    if (prevVin !== thisVin && thisVin) {
      this.props.searchForVin(thisVin)
    }
  }

  closeModal() {
    this.props.onClose()
  }

  renderHeader() {
    return (
      <Row gap={2}>
        <Col span={2}>
          <Button icon={<ArrowLeftOutlined />} onClick={this.props.onClose}>
            <FormattedMessage id="generic.back" />
          </Button>
        </Col>
        <Col span={22} className="modal-title-container">
          <FormattedMessage id="tracer.switchTest.modalHeader" />
        </Col>
      </Row>
    )
  }

  renderContent() {
    return (
      <VehicleTestsTable
        currentVehicleTest={this.props.currentVehicleTest}
        data={this.props.vehicleTests}
        onViewButtonClick={this.closeModal}
        showVin={false}
      />
    )
  }

  render() {
    return (
      <ModalFullScreen
        HeaderProp={this.renderHeader}
        ContentProp={this.renderContent}
        isOpen={this.props.isOpen}
      />
    )
  }
}

export default SwitchTest
