import React from 'react'
import { useMeasure, useDebounce } from 'react-use'
import { Table, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import {
  useGlobalSearchContext,
  useGlobalSearchDispatch,
} from './GlobalSearchContext'
import { useAddEphemeralVehicleTest } from '../hooks/EphemeralVehicleTest'
import { generateTestPayload } from '../services/test-payload'
import { useSelector, shallowEqual } from 'react-redux'
import { useMakeModelByVin } from '../hooks/MakeModels'
import {
  RelatedCircuitsModal,
  CircuitDTCsProvider,
  RelatedCircuitsIconButton,
} from '../CircuitDTCs'
import { useRelatedCircuits } from '../hooks/CircuitDTCs'

export const GenerateEphemeralVehicleTestButton = () => {
  const context = useGlobalSearchContext()
  const user = useSelector((state) => state.user.data.username, shallowEqual)
  const addEphemeralVehicleTest = useAddEphemeralVehicleTest()
  const history = useHistory()

  const onClick = () => {
    const payload = generateTestPayload({
      vin: context.vin,
      userId: user,
      dtcs: context.dtcSelection.dtcs,
    })
    addEphemeralVehicleTest.mutate(
      { payload },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/vehicle-test/preview`, { result: data.data }),
      },
    )
  }

  return (
    <Button
      disabled={context.dtcSelection.dtcs.length === 0}
      onClick={onClick}
      type="primary"
      loading={addEphemeralVehicleTest.isLoading}
    >
      <FormattedMessage id="globalSearch.manualDtc.generateVehicleTestButton" />
    </Button>
  )
}

const dtcColumns = [
  {
    title: <FormattedMessage id="globalSearch.resultsTable.dtcResults.name" />,
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.dtcResults.acronym" />
    ),
    dataIndex: 'acronym',
    key: 'acronym',
    width: '15%',
  },
  {
    title: (
      <FormattedMessage id="globalSearch.resultsTable.commonColumns.description" />
    ),
    dataIndex: 'description',
    key: 'description',
    textWrap: 'word-break',
    ellipsis: true,
  },
]

const ResultsTable = ({ columns, results, isLoading, dataTestId, rowKey }) => {
  const [containerRef, { height: containerHeight }] = useMeasure()
  const globalSearchDispatch = useGlobalSearchDispatch()
  const context = useGlobalSearchContext()
  const [tableHeight, setTableHeight] = React.useState(0)

  // containerHeight changes its height multiple times during re-render
  // but only the largest (last) value is needed.
  useDebounce(
    () => {
      setTableHeight(containerHeight - 50) // 50 is the height of header.
    },
    1,
    [containerHeight],
  )

  return (
    <div className="global-search-results-table" ref={containerRef}>
      <Table
        data-testid={dataTestId}
        size="middle"
        columns={columns}
        dataSource={results}
        loading={isLoading}
        pagination={false}
        scroll={{
          y: tableHeight,
        }}
        rowKey={rowKey}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows, info) => {
            globalSearchDispatch.handleDtcsSelection({ dtcs: selectedRows })
          },
          selectedRowKeys: context.dtcSelection.dtcs.map((dtc) => rowKey(dtc)),
        }}
      />
    </div>
  )
}

const getKey = ({ dtc, acronym }) => `${dtc}-${acronym}`

export const DtcResults = ({ results, isLoading }) => {
  const globalSearchContext = useGlobalSearchContext()
  const { makeModel } = useMakeModelByVin({
    vin: globalSearchContext.vin,
  })

  const [dtcs, setDTCs] = React.useState([])
  const [relatedCircuits, setRelatedCircuits] = React.useState({})

  const { isLoading: circuitDTCsLoading, circuits } = useRelatedCircuits({
    makeModelId: makeModel?.id,
    dtcs: [...dtcs].sort((a, b) =>
      getKey({ dtc: a.dtc, acronym: a.acronym }).localeCompare(
        getKey({ dtc: b.dtc, acronym: b.acronym }),
      ),
    ),
  })

  React.useEffect(() => {
    if (!results) return
    setDTCs(results.map((dtc) => ({ dtc: dtc.name, acronym: dtc.acronym })))
  }, [results])

  React.useEffect(() => {
    if (!circuits) return
    setRelatedCircuits(
      circuits.reduce((acc, obj) => {
        const key = getKey({ dtc: obj.dtc, acronym: obj.acronym })
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {}),
    )
  }, [circuits])

  const columns = [
    ...dtcColumns,
    {
      title: (
        <FormattedMessage id="globalSearch.resultsTable.circuitResults.circuits" />
      ),
      render: (value, record, index) => {
        const key = getKey({ dtc: record.name, acronym: record.acronym })
        return (
          <CircuitDTCsProvider>
            <RelatedCircuitsIconButton
              relatedCircuits={relatedCircuits[key]}
              disabled={!(key in relatedCircuits)}
              makeModelId={makeModel?.id}
              dtc={record.name}
              acronym={record.acronym}
              loading={circuitDTCsLoading}
            />
            <RelatedCircuitsModal />
          </CircuitDTCsProvider>
        )
      },
      width: '10%',
    },
  ]

  return (
    <ResultsTable
      columns={columns}
      results={results}
      loading={isLoading}
      dataTestId="dtc-results"
      rowKey={(record) => `${record.name}-${record.acronym}`}
    />
  )
}

export const ManualDtcs = () => {
  const context = useGlobalSearchContext()
  return (
    <ResultsTable
      columns={dtcColumns}
      results={context.dtcSelection.dtcs}
      loading={false}
      dataTestId="manual-dtcs"
      rowKey={(record) => `${record.name}-${record.acronym}`}
    />
  )
}
