import { useEffect, useMemo, useState } from 'react'
import { useGetIssues } from '../hooks/Issues'
import { Flex, Table } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  fetchProblemLogsForIssues,
  linkProblemLogToIssue,
  deleteIssue,
} from '../api/issues'
import { Button, Popconfirm } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { IssueSearch } from './IssueSearch'
import { PlantButton } from '../Reports/PlantButton'
import { useCheckPermission } from '../hooks/Permissions'
import { IssueExportButton } from './IssueExportButton'
// This is the list of issues on the right hand side of the figma when
// we don't have a defined fault/issue
// https://www.figma.com/proto/ITEOBzG70wVkwRkep3rc0B/Tracer---Ant-Design?page-id=9316%3A23734&node-id=9613-21823&node-type=frame&viewport=-64%2C334%2C0.12&t=KPS4u8Suia3eGyRO-1&scaling=contain&content-scaling=fixed&starting-point-node-id=9607%3A15334
// If you pass in issues it will just display a table of those,
// otherwise it displays all issues
// If you pass in a fault it will have the option of linking that fault to
// the issues
const storageKey = 'incident-mgmt-faults'

const IssueFirstCase = ({ issue, problemLogs }) => {
  if (!!problemLogs && problemLogs.length > 0 && !!issue) {
    problemLogs.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
    return dayjs(problemLogs[0].created_at).format('MM/DD/YYYY h:mma')
  }
  return ''
}

const EditOrAttachToIssueButton = ({
  issue,
  problemLogs,
  isAttachingCases,
  fault,
  intl,
}) => {
  let history = useHistory()
  const attachFaultToIssue = () => {
    linkProblemLogToIssue(issue.id, fault.id).then(() => {
      const url = `${window.location.origin}/incidents`
      window.open(url, '_self')
    })
  }

  if (isAttachingCases) {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={attachFaultToIssue}
        size="small"
      >
        {intl.formatMessage({
          id: 'incidentMgmt.issueList.addCase',
          defaultMessage: 'Add Case',
        })}
      </Button>
    )
  }
  if (!!problemLogs && problemLogs.length > 0) {
    const fault = problemLogs[0]
    fault['attachedIssue'] = issue
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          const faultStr = JSON.stringify(fault)
          localStorage.setItem(storageKey, faultStr)
          const url = `/incidents?fault=${fault.id}`
          history.push(url)
        }}
        size="small"
      >
        <EditOutlined />
      </Button>
    )
  }

  return ''
}

const IssueDeleteButton = ({ issue, removeIssue, intl }) => {
  return (
    <Popconfirm
      title={intl.formatMessage({
        defaultMessage: 'Delete Issue?',
        id: 'incidentMgmt.issueDeleteButton.deleteIssue',
      })}
      onConfirm={() => removeIssue(issue)}
      okText={intl.formatMessage({
        defaultMessage: 'Yes',
        id: 'incidentMgmt.issueDeleteButton.deleteYes',
      })}
      cancelText={intl.formatMessage({
        defaultMessage: 'No',
        id: 'incidentMgmt.issueDeleteButton.deleteNo',
      })}
      okType="danger"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <Button variant="outlined" size="small" danger>
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  )
}

export const IssueList = ({ fault, issues, setIssue, setFault, intl }) => {
  let history = useHistory()
  const { issues: allIssues, isLoading } = useGetIssues()
  const [selectedIssue, setSelectedIssue] = useState(null)
  const [problemLogsForIssues, setProblemLogsForIssues] = useState({})
  const [isAttachingCases, setIsAttachingCases] = useState(false)
  const checkPermission = useCheckPermission()
  const canDeleteIssues = checkPermission('issues:delete')

  const removeIssue = (issue) => {
    if (!canDeleteIssues) {
      return
    }
    deleteIssue(issue.id).then((data) => {
      const { deleted_issue_id } = data
      for (let i = 0; i < allIssues.length; i++) {
        if (allIssues[i].id === deleted_issue_id) {
          allIssues[i].deleted = true
        }
      }
      if (issues) {
        for (let i = 0; i < issues.length; ++i) {
          if (issues[i].id === deleted_issue_id) {
            issues[i].deleted = true
          }
        }
      }
      selectIssuesToShow()
    })
  }

  const cols = useMemo(() => {
    let cols = [
      {
        title: intl.formatMessage({
          id: 'incidentMgmt.issueList.issue',
          defaultMessage: 'Issue',
        }),
        dataIndex: ['id'],
      },
      {
        title: intl.formatMessage({
          id: 'incidentMgmt.issueList.description',
          defaultMessage: 'Description',
        }),
        dataIndex: ['description'],
      },
      {
        title: intl.formatMessage({
          id: 'incidentMgmt.issueList.firstCase',
          defaultMessage: 'First Case',
        }),
        render: (record) =>
          IssueFirstCase({
            issue: record,
            problemLogs: problemLogsForIssues[record.id],
          }),
      },
      {
        title: intl.formatMessage({
          id: 'incidentMgmt.issueList.cleanPoint',
          defaultMessage: 'Clean point',
        }),
        render: (record) => {
          return record.closed_at
            ? dayjs(record.closed_at).format('MM/DD/YYYY h:mma')
            : '--'
        },
      },
      {
        title: intl.formatMessage({
          id: 'incidentMgmt.issueList.status',
          defaultMessage: 'Status',
        }),
        dataIndex: ['status'],
      },
      {
        title: '#',
        render: (record) =>
          !!problemLogsForIssues[record.id]
            ? problemLogsForIssues[record.id].length
            : 0,
      },
      {
        title: '',
        sorting: false,
        render: (record) =>
          EditOrAttachToIssueButton({
            fault: fault,
            issue: record,
            problemLogs: problemLogsForIssues[record.id],
            isAttachingCases: isAttachingCases,
            intl: intl,
          }),
      },
    ]

    if (canDeleteIssues) {
      cols.push({
        title: '',
        render: (record) =>
          IssueDeleteButton({
            issue: record,
            removeIssue,
            intl,
          }),
      })
    }
    return cols
  }, [selectedIssue, setSelectedIssue, problemLogsForIssues, isAttachingCases])

  const [issuesToShow, setIssuesToShow] = useState([])

  const selectIssuesToShow = () => {
    if (!!issues) {
      setIssuesToShow(issues.filter((issue) => !issue.deleted))
      setIsAttachingCases(true)
    } else if (!!fault && !!fault.relatedIssues) {
      // just in case these were passed here
      setIssuesToShow(fault.relatedIssues.filter((issue) => !issue.deleted))
      setIsAttachingCases(true)
    } else if (!!allIssues) {
      setIssuesToShow(allIssues.filter((issue) => !issue.deleted))
      setIsAttachingCases(false)
    }
  }

  // passed to IssueSearch
  const [searchTerm, setSearchTerm] = useState('')
  const [isUsingSearch, setIsUsingSearch] = useState(false)
  const updateIssuesToShow = (issues) => {
    if (issues === null) {
      selectIssuesToShow()
    } else {
      setIsUsingSearch(true)
      setIssuesToShow(issues)
    }
  }

  useEffect(() => {
    selectIssuesToShow()
  }, [issues, allIssues])

  useEffect(() => {
    if (!!issuesToShow && issuesToShow.length > 0) {
      const issueIds = issuesToShow
        .filter((issue) => !!issue.id)
        .map((issue) => issue.id)
      fetchProblemLogsForIssues(issueIds).then((plsForIssues) => {
        // this is to make the fault description work
        // the same as it does with a fault from the fault report
        Object.keys(plsForIssues).forEach((issue_id) => {
          const probs = structuredClone(plsForIssues[issue_id])
          plsForIssues[issue_id].forEach((prob, idx) => {
            plsForIssues[issue_id][idx]['problems'] = probs
          })
        })
        setProblemLogsForIssues(plsForIssues)
        setSelectedIssue(issuesToShow[0])
      })
    } else {
      setSelectedIssue(null)
    }
  }, [issuesToShow])

  useEffect(() => {
    if (!!selectedIssue) {
      const issueId = selectedIssue.id
      let probLog = null
      if (!!problemLogsForIssues[issueId]) {
        if (problemLogsForIssues[issueId].length > 0) {
          probLog = problemLogsForIssues[issueId][0]
        }
      }
      setFault(probLog)
      setIssue(selectedIssue)
    } else {
      setFault(null)
      setIssue(null)
    }
  }, [selectedIssue])

  return (
    <Flex id="issue-list" vertical={true} gap={10}>
      {isAttachingCases && (
        <Flex id="issue-list-header" vertical={false} gap={10}>
          <span id="num-prev-issues">
            {issuesToShow.length}{' '}
            {intl.formatMessage({
              id: 'incidentMgmt.issueList.previousIssues',
              defaultMessage: 'Previous Issues',
            })}
          </span>
          <Button
            id="issue-list-add-issue-header-btn"
            type="primary"
            variant="solid"
            onClick={() => {
              const faultStr = JSON.stringify(fault)
              localStorage.setItem(storageKey, faultStr)
              const url = `/incidents?fault=${fault.id}`
              history.push(url)
            }}
            size="small"
          >
            {intl.formatMessage({
              id: 'incidentMgmt.issueList.addIssue',
              defaultMessage: 'Add Issue',
            })}
          </Button>
        </Flex>
      )}
      <div id="issue-list-filter-ctrls">
        <PlantButton intl={intl} />
        <IssueSearch
          issues={issuesToShow}
          updateIssuesToShow={updateIssuesToShow}
          problemLogsForIssues={problemLogsForIssues}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          intl={intl}
        />
      </div>
      {issuesToShow.length > 0 ? (
        <Table
          columns={cols}
          dataSource={issuesToShow}
          id="issue-list"
          pagination={{
            pageSize: 4,
            hideOnSinglePage: true,
          }}
          rowSelection={{
            type: 'radio',
            onSelect: (record) => {
              setSelectedIssue(record)
            },
            selectedRowKeys: selectedIssue ? [selectedIssue.id] : [],
          }}
          rowKey={(record) => record.id}
        />
      ) : (
        intl.formatMessage({
          id: 'incidentMgmt.issueList.noIssues',
          defaultMessage: 'No Issues',
        })
      )}
      {issuesToShow.length > 0 && (
        <IssueExportButton
          issues={issuesToShow}
          problemLogs={problemLogsForIssues}
          intl={intl}
        />
      )}
    </Flex>
  )
}
