import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  createConnectorSetFromHiglightedDtcs,
  createConnectorSetFromToggledDtcs,
  createDtcList,
  getHighlightedDTCs,
  getCurrentDTC,
} from '../selectors'
import Cytoscape from '../components/Cytoscape'
import { selectElement } from '../thunks/component'
import { toggleOffDtc, highlightDtcs } from '../actions/dtc-actions'
import { MAIN_NAMESPACE } from '../constants'
import isEmpty from 'lodash/isEmpty'
import Header from '../components/Header'
import { getElementName } from '../helpers/element'

const HarnessViewGraphWrapper = (props) => {
  const theme = props.theme

  const getTitle = () => {
    if (props.selectedElement.isNode) {
      return getElementName(props.selectedElement)
    } else if (props.selectedElement.isEdge) {
      return props.selectedElement.harness_id
    }
    return null
  }

  const getSubTitle = () => {
    if (props.selectedElement.isNode) {
      return null
    } else if (props.selectedElement.isEdge) {
      return props.selectedElement.level
    }
    return null
  }

  return (
    <>
      {!props.isEmbedded && (
        <>
          {props.selectedElement && (
            <Header
              title={getTitle()}
              subtitle={props.selectedElement.description}
              extendedtitle={getSubTitle()}
            />
          )}
          {!props.selectedElement && (
            <Header title="" subtitle="" extendedtitle="" />
          )}
          <Cytoscape {...props} theme={theme} />
        </>
      )}
      {props.isEmbedded && <Cytoscape {...props} theme={theme} />}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const highlightedDtcs = getHighlightedDTCs(state)
  const toggledDtc = getCurrentDTC(state)
  let connectorSet = new Set()

  if (!isEmpty(toggledDtc)) {
    connectorSet = createConnectorSetFromToggledDtcs(state)
  }
  if (!isEmpty(highlightedDtcs)) {
    connectorSet = createConnectorSetFromHiglightedDtcs(state)
  }

  return {
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    centerOnSelectedElement: state.componentState.centerOnSelectedElement,
    componentStates: state.loggingState.componentStates,
    deviceStates: state.loggingState.deviceStates,
    connectorSet: connectorSet,
    toggledOnDtc: getCurrentDTC(state),
    currentTab: _.get(state.tabState.currentTab, MAIN_NAMESPACE),
    elements: state.componentState.graphData,
    makeModelId: state.componentState.makeModelId,
    selectedElement: state.componentState.selectedElement,
    sidebarOpen: state.sidebar.open,
    theme: state.app.theme,
    tooltipEnabled: true,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    historiesForComponents: state.loggingState.historiesForComponents,
    zoomInControl: ownProps.zoomInControl,
    zoomOutControl: ownProps.zoomOutControl,
    zoomResetControl: ownProps.zoomResetControl,
    panControls: ownProps.panControls,
    layout: ownProps.layout,
    userPanningEnabled: ownProps.userPanningEnabled,
    userZoomingEnabled: ownProps.userZoomingEnabled,
    isEmbedded: ownProps.embeddedMode,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGraphElementSelected: (element, dtcs) => {
      if (
        element.isEdge ||
        element.category != 'splice' ||
        (element.category == 'splice' && element.images.vehicle_file_name)
      ) {
        const componentIds = (element.isEdge && [
          element.source,
          element.target,
        ]) || [element.id]
        dispatch(
          highlightDtcs(
            _.chain(dtcs)
              .filter('components')
              .filter((dtc) =>
                _.find(componentIds, (componentId) =>
                  dtc.components.has(componentId),
                ),
              )
              .value(),
          ),
        )
        dispatch(selectElement(element, false))
        dispatch(toggleOffDtc())
      }
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HarnessViewGraphWrapper)
