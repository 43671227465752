import React from 'react'
import classnames from 'classnames'
import tracerLogo from '../assets/images/headertracerlogo.png'
import url from '../services/url'
import { Flex } from 'antd'

export default function Logo({ navigateToDashboard, className }) {
  const [customerLogo, setCustomerLogo] = React.useState(null)
  const client = url.getCustomerSubdomainPart()

  const classNames = classnames('navbar-logo', 'clickable', className)

  import(`../assets/images/logo-${client}.png`)
    .then((module) => {
      setCustomerLogo(module.default)
    })
    .catch(() => {
      // Customer can be without logo.
    })

  return (
    <a onClick={navigateToDashboard} className={classNames}>
      {customerLogo ? (
        <Flex className="logo-container" vertical={false} gap={5}>
          <div className="logo-image-container">
            <img src={tracerLogo} alt="tracer logo" className="tracer-logo" />
          </div>
          <div className="logo-image-container">
            <img
              src={customerLogo}
              alt="customer logo"
              className="customer-logo"
            />
          </div>
        </Flex>
      ) : (
        <img src={tracerLogo} alt="tracer logo" />
      )}
    </a>
  )
}
