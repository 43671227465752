import React, { useState, useEffect } from 'react'
import { Input, Select, Flex, Button } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

export const EmailField = ({ onChange, onBlur, value, error }) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <Flex vertical={true} gap="small">
        <label>
          <FormattedMessage id="users.email" />:
        </label>
        <Input
          type="email"
          name="email"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={intl.formatMessage({ id: 'users.email' })}
        />
      </Flex>
      {error && <div className="form-text-error">{error}</div>}
    </React.Fragment>
  )
}

export const RoleField = ({ onChange, value, error, options }) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <Flex vertical={true} gap="small">
        <label>
          <FormattedMessage id="users.role" />:
        </label>
        <Select
          name="role"
          options={options}
          onChange={onChange}
          value={value == '' ? [] : value}
          placeholder={intl.formatMessage({ id: 'users.role' })}
        />
      </Flex>
      {error && <div className="form-text-error">{error}</div>}
    </React.Fragment>
  )
}

export const PlantField = ({ role, options, onChange, value, error }) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <Flex vertical={true} gap="small">
        <label>
          <FormattedMessage id="users.plant" />:
        </label>
        <Select
          className="select"
          name="plant"
          options={options}
          onChange={onChange}
          mode="multiple"
          value={value}
          placeholder={intl.formatMessage({ id: 'users.plant' })}
        />
      </Flex>
      {error && <div className="form-text-error">{error}</div>}
    </React.Fragment>
  )
}

export const DisabledField = ({ options, onChange, value }) => {
  const intl = useIntl()
  return (
    <Flex vertical={true} gap="small">
      <label>
        <FormattedMessage id="users.disabled" />:
      </label>
      <Select
        className="select"
        isClearable={false}
        name="disabled"
        options={options}
        onChange={onChange}
        value={value}
        placeholder={intl.formatMessage({ id: 'users.disabled' })}
      />
    </Flex>
  )
}

export const Buttons = ({ isSaving, onSubmit, values }) => {
  const history = useHistory()
  const [disabled, toggleDisabled] = useState(true)

  useEffect(() => {
    if (
      values &&
      values.email?.length != 0 &&
      values.role?.length != 0 &&
      values.plant
    ) {
      toggleDisabled(false)
    } else {
      toggleDisabled(true)
    }
  }, [values])

  return (
    <Flex vertical={false} gap="large" justify="center">
      <Button
        type="primary"
        disabled={disabled}
        onClick={() => onSubmit(values)}
      >
        {isSaving ? (
          <FormattedMessage id="processes.saving" />
        ) : (
          <FormattedMessage id="forms.save" />
        )}
      </Button>
      <Button
        type="default"
        onClick={() => history.push(`/users`)}
        disabled={isSaving}
        className="user-form-cancel"
      >
        <FormattedMessage id="forms.cancel" />
      </Button>
    </Flex>
  )
}
