import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import isEmpty from 'lodash/isEmpty'

import SelectWithRadioButtons from '../Fields/SelectWithRadioButtons'
import EmbeddedSelectList from '../../EmbeddedMode/Select'
import { MAX_NUMBER_OF_SUGGESTED_FAULTS } from '../../constants'
import { isEmbeddedMode } from '../../services/navigation'
import { linkProblemLogToIssue } from '../../api/issues'
import { useGetIncidentMgmtConfig } from '../../hooks/Issues'

import { Modal, Button, Select, Row, Flex, Input } from 'antd'

const ComponentStateModal = (props) => {
  const { TextArea } = Input
  const {
    historyForComponent,
    intl,
    isOpen,
    faults,
    onClose,
    onSubmit,
    placeHolder,
    title,
    component,
    graphEdges,
    makeModelId,
    vin,
  } = props

  const [annotation, setAnnotation] = React.useState('')
  const [reason, setReason] = React.useState(null)
  const { config: incidentMgmtConfig } = useGetIncidentMgmtConfig({
    make_model_id: makeModelId,
    vin,
  })
  const [allowLinkingIssues, setAllowLinkingIssues] = React.useState(false)
  useEffect(() => {
    if (
      !isEmpty(incidentMgmtConfig) &&
      !isEmpty(incidentMgmtConfig[makeModelId])
    ) {
      const cfg = incidentMgmtConfig[makeModelId]
      setAllowLinkingIssues(!!cfg['link_issues_on_rootcause_modal'])
    } else {
      setAllowLinkingIssues(false)
    }
  }, [incidentMgmtConfig])

  useEffect(() => {
    if (component?.category == 'splice') {
      const edge = graphEdges.filter((edge) => {
        const fromId = edge.data.from_category
          ? edge.data.from_category.substring(
              0,
              edge.data.from_category.lastIndexOf('-'),
            )
          : ''
        const toId = edge.data.to_category
          ? edge.data.to_category.substring(
              0,
              edge.data.to_category.lastIndexOf('-'),
            )
          : ''
        if (fromId == component.id || toId == component.id) return edge
      })

      if (edge[0]) setAnnotation(edge[0].data.harness)
    }
  }, [component])

  const location = useLocation()

  const getAttachedIssueID = (prob) => {
    const flts = historyForComponent[prob]
    if (isEmpty(flts)) {
      return null
    }
    for (let i = 0; i < flts.length; i++) {
      if (flts[i].attached_issue_id) {
        return flts[i].attached_issue_id
      }
    }
    return null
  }

  const getSuggestedFaultList = () => {
    if (isEmpty(historyForComponent)) return []
    const suggestedItems = Object.keys(historyForComponent).slice(
      0,
      MAX_NUMBER_OF_SUGGESTED_FAULTS,
    )
    const formattedItems = suggestedItems.map((reason) => {
      let lbl = reason
      const res = {
        value: reason,
        label: lbl,
      }
      if (allowLinkingIssues) {
        const issueID = getAttachedIssueID(lbl)
        if (issueID) {
          res['label'] = `${reason} (${issueID.toUpperCase()})`
        }
        res['issueID'] = issueID
      }
      return res
    })
    return formattedItems
  }
  const [suggestedFaults, setSuggestedFaults] = React.useState([])
  useEffect(() => {
    setSuggestedFaults(getSuggestedFaultList())
  }, [allowLinkingIssues])

  const renderFaultSelection = () => {
    const allOptions = faults.map((fault) => ({
      value: fault.key,
      label: fault.key,
    }))
    const suggestedOptions = suggestedFaults
    const options = { allOptions, suggestedOptions }

    return (
      <div>
        <label>
          <FormattedMessage id="tracer.logFailure.typeLabel" />
        </label>
        <SelectWithRadioButtons
          name="reason"
          options={options}
          placeholder={placeHolder}
          titleText={
            <FormattedMessage id="tracer.logFailure.applyRecentChanges" />
          }
          onChange={(reason) => {
            setReason(reason)
          }}
          selected={reason}
        />
      </div>
    )
  }

  const renderEmbedded = () => {
    const suggestedOptions = getSuggestedFaultList().map((option) => ({
      ...option,
      isSuggested: true,
    }))

    // Exclude suggested from all faults
    const otherOptions = faults
      .filter((fault) => {
        if (
          !suggestedOptions.some((suggested) => suggested.value === fault.key)
        ) {
          return fault
        }
      })
      .map((fault) => ({ value: fault.key, label: fault.key }))

    // Suggested should be on top
    const options = [...suggestedOptions, ...otherOptions]

    return (
      <div className="row">
        <EmbeddedSelectList
          options={options}
          selected={reason}
          onChange={setReason}
        />
      </div>
    )
  }

  const renderAnnotation = () => {
    return (
      <div>
        <label>
          <FormattedMessage id="tracer.logFailure.commentLabel" />
        </label>
        <TextArea
          id="comment"
          rows={4}
          value={annotation}
          placeholder={intl.formatMessage({
            id: 'tracer.logFailure.commentPlaceholder',
          })}
          onChange={(e) => setAnnotation(e.target.value)}
        />
      </div>
    )
  }

  const onSubmitClick = (e) => {
    e.preventDefault()

    onSubmit({
      annotation: annotation ? annotation : null,
      reason: reason,
    }).then((resp) => {
      if (allowLinkingIssues) {
        const issueID = reason.issueID
        if (issueID) {
          return linkProblemLogToIssue(issueID, resp.id)
        }
      }
      return resp
    })

    onClose()

    return false
  }

  return (
    <Modal
      open={isOpen}
      title={
        <div className="fault-modal-header">
          <div className="fault-modal-title">{title}</div>
          <div className="fault-modal-common-title">
            <FormattedMessage id="tracer.logFailure.title" />
          </div>
        </div>
      }
      onOk={onSubmitClick}
      onCancel={onClose}
      footer={[
        <Button onClick={onClose}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button
          type="primary"
          onClick={onSubmitClick}
          disabled={isEmpty(reason)}
        >
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
      centered={true}
    >
      <Flex gap="middle" vertical={true}>
        {isEmbeddedMode(location.search) ? (
          renderEmbedded()
        ) : (
          <>
            {renderFaultSelection()}
            {renderAnnotation()}
          </>
        )}
      </Flex>
    </Modal>
  )
}

export default injectIntl(ComponentStateModal)
