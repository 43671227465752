import axiosWrapper from './axios-wrapper'

const api = axiosWrapper.create({
  baseURL: '/tracer/notify',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiSendNotification({
  recipient,
  subject,
  issue_id,
  issue_description,
  issue_action_category,
  issue_action_start_date,
  issue_action_due_date,
  issue_action_description,
}) {
  return api.post('/send-action-notification', {
    recipient,
    subject,
    issue_id,
    issue_description,
    issue_action_category,
    issue_action_start_date,
    issue_action_due_date,
    issue_action_description,
  })
}
