import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { clearPinDestination } from '../actions/pin-actions'
import {
  unsetModal,
  setModal,
  setConnectorImage,
} from '../actions/modal-actions'
import PinDestination from '../components/PinDestination/PinDestination'
import { MODAL_CONNECTOR_VIEW } from '../constants'

class PinDestinationContainer extends Component {
  render() {
    return <PinDestination {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    pinDestination: state.pinState.pinDestination,
    selectedElement: state.pinState.selectedElement,
    pin: state.pinState.pinNumber,
    vin: get(state, 'vehicleTests.currentVehicleTest.vin'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(clearPinDestination())
      dispatch(unsetModal())
    },
    showConnectorModal: (pins, imageUrl) => {
      dispatch(setConnectorImage(pins, imageUrl))
      dispatch(setModal(MODAL_CONNECTOR_VIEW))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PinDestinationContainer)
