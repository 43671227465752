import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { unsetModal } from '../../actions/modal-actions'
import ComponentStateModal from './index.js'
import { logDeviceProblem } from '../../api/controller-api'
import { clearInlineComponents } from '../../actions/harness-actions'
import { getDtcCodesFromHighlightedDtcs } from '../../selectors'
import { getElementName } from '../../helpers/element'

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
    vehicleTestResultId: state.componentState.vehicleTestResultId,
    makeModelId: state.componentState.makeModelId,
    dtcs: getDtcCodesFromHighlightedDtcs(state),
    vin: state.componentState.vin,
    selectedComponent: state.modalState.component,
    faults: state.loggingState.faults.device,
    title: getElementName(state.modalState.component),
    placeHolder: (
      <FormattedMessage id="tracer.logFailure.typePlaceholderDevice" />
    ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClose: () => {
      dispatch(clearInlineComponents())
      dispatch(unsetModal())
    },
    _onSubmit: ({
      annotation,
      vehicleTestResultId,
      deviceName,
      dtcs,
      isProblem,
      makeModelId,
      pinNumber,
      reason,
      vin,
    }) => {
      const res = logDeviceProblem({
        annotation,
        vehicleTestResultId,
        deviceName,
        dtcs,
        isProblem,
        makeModelId,
        pinNumber,
        reason,
        vin,
      })
      dispatch(unsetModal())
      return res
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, // optional
    ...dispatchProps, // optional
    onSubmit: (args) => {
      return dispatchProps._onSubmit({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
        isProblem: true,
        dtcs: stateProps.dtcs,
        deviceName: stateProps.selectedComponent.id,
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ComponentStateModal)
