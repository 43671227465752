import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { Modal, Button, Checkbox } from 'antd'
import history from '../history'
import { useUserPlant, useUserTestZones } from '../hooks/User'
import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR, GRAY_COLOR } from '../constants'

import CloseButton from './CloseButton'
import { update } from 'lodash'
import { useGetUserTestZones } from '../hooks/TestZones'
import { Loadable } from './Loading'

const SelectTestZones = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [userPlant] = useUserPlant()
  const [userTestZones, setUserTestZones] = useUserTestZones()
  const { isLoading, allTestZones } = useGetUserTestZones()
  const [, , remove] = useLocalStorage('reports-query')
  const [selectedTestZones, selectTestZones] = useState(userTestZones)
  const [testZonesModified, setTestZonesModified] = useState(false)

  const availableTestZones = allTestZones.filter(
    (t) => t.plant.name.toLowerCase() == userPlant.toLowerCase(),
  )

  const isTestZoneSelected = (testZone) => {
    return selectedTestZones.some((t) => t.specifier === testZone)
  }

  const updateSelectedTestZones = (testZone) => {
    const currentlySelected = isTestZoneSelected(testZone)
    if (currentlySelected) {
      // remove the test zone
      selectTestZones(selectedTestZones.filter((t) => t.specifier !== testZone))
    } else {
      // add the test zone
      const toAdd = availableTestZones.filter((t) => t.specifier === testZone)
      selectTestZones(selectedTestZones.concat(toAdd))
    }
    setTestZonesModified(true)
  }

  const onSubmitClick = (event) => {
    event.preventDefault()
    remove()
    setUserTestZones(selectedTestZones)
    closeModal()
    if (location.pathname == '/reports') history.go(0)
  }

  return (
    <Modal
      className="select-test-zone-modal"
      open={isOpen}
      title={<FormattedMessage id="selectTestZones.title" />}
      onCancel={closeModal}
      centered={true}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button
          type="primary"
          onClick={onSubmitClick}
          disabled={!testZonesModified}
        >
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
    >
      <Loadable isLoading={isLoading} showText={false}>
        {availableTestZones.map((availableTestZone) => {
          return (
            <div
              className="checkbox"
              key={availableTestZone.description}
              id={availableTestZone.specifier}
            >
              <Checkbox
                id={availableTestZone.specifier}
                value={availableTestZone.specifier}
                checked={isTestZoneSelected(availableTestZone.specifier)}
                onChange={() =>
                  updateSelectedTestZones(availableTestZone.specifier)
                }
              >
                {availableTestZone.description}
              </Checkbox>
            </div>
          )
        })}
      </Loadable>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectTestZones),
)
