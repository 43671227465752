import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetRelatedDTCs({ makeModelId, circuits }) {
  const params = new URLSearchParams({ circuits: circuits.join() })
  return api
    .get(`/make-model-id/${makeModelId}/dtcs?${params.toString()}`)
    .then((resp) => resp.data)
}

export function apiGetRelatedCircuits({ makeModelId, dtcs }) {
  const params = new URLSearchParams({ dtcs: JSON.stringify(dtcs) })
  return api
    .get(`/make-model-id/${makeModelId}/circuits?${params.toString()}`)
    .then((resp) => resp.data)
}
