import React from 'react'
import { components } from 'react-select'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  DEFAULT_TIME_AFTER_TEST,
  MAX_INPUT_TIME_AFTER_TEST_VALUE,
} from './constants'
import { useGetUserTestZones } from '../hooks/TestZones'
import { Button, Select, Input, Flex, Form } from 'antd'

function DashboardControl({ searchTerm, setSearchTerm, intl }) {
  const testZoneElemet = React.useRef(null)
  const [plant, setPlant] = React.useState(searchTerm.plant)

  const [testZones, setTestZones] = React.useState(searchTerm.testZones)
  const [timeAfterTest, setTimeAfterTest] = React.useState(
    searchTerm.timeAfterTest,
  )

  const { status, allTestZones, error } = useGetUserTestZones()

  if (status === 'loading') {
    return (
      <span>
        <FormattedMessage id="productionLineDashboard.dashboardControl.loadingTestZone" />
      </span>
    )
  } else if (status === 'error') {
    return (
      <span>
        <FormattedMessage id="productionLineDashboard.dashboardControl.error" />{' '}
        {error.message}
      </span>
    )
  }

  const allPlants = [
    ...new Set(allTestZones.map((zone) => zone.plant.name)),
  ].map((plantName) => ({ value: plantName, label: plantName }))

  const getTestZonesByPlant = ({ testZones, plantName }) => {
    const testZonesFilters = testZones
      .filter((zone) => zone.plant.name === plantName)
      .map((zone) => ({
        value: zone.id,
        label: `${zone.specifier} - ${zone.description}`,
      }))
    return testZonesFilters
  }

  // In most of the cases users of this feature will have only one plant.
  // In this case, preselect this plant and corresponding test zones.
  if (!plant && allPlants.length === 1) {
    setPlant(allPlants[0])
    setTestZones(
      getTestZonesByPlant({
        testZones: allTestZones,
        plantName: allPlants[0].value,
      }),
    )
  }

  const onPlantChange = (selectedPlant) => {
    setPlant({ value: selectedPlant, label: selectedPlant })
    setTestZones([])
    testZoneElemet.current.focus()
  }

  const onTestZoneChange = (selectedTestZone) => {
    setTestZones(selectedTestZone)
  }

  const isInteger = (value) => !isNaN(value) && Number.isInteger(Number(value))

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    if (inputValue != '' && isInteger(inputValue)) {
      if (Number(inputValue) <= MAX_INPUT_TIME_AFTER_TEST_VALUE) {
        setTimeAfterTest({ value: inputValue * 60, label: inputValue })
      }
    } else if (inputValue == '') {
      setTimeAfterTest({ value: 0, label: '' })
    }
  }

  const onApplyClick = () => {
    setSearchTerm({ plant, testZones, timeAfterTest })
  }

  return (
    <Flex
      vertical={false}
      gap="middle"
      justify="center"
      align="center"
      className="production-line-form"
    >
      <Form.Item
        label={
          <FormattedMessage id="productionLineDashboard.dashboardControl.plant" />
        }
        className="production-line-plant"
        layout="vertical"
      >
        <Select
          data-cy="production-line-plant"
          showSearch
          autoFocus={!plant}
          options={allPlants}
          onChange={onPlantChange}
          placeholder={intl.formatMessage({
            id: 'productionLineDashboard.dashboardControl.selectPlantPlaceholder',
          })}
          value={plant}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id="productionLineDashboard.dashboardControl.testZone" />
        }
        className="production-line-test-zone"
        layout="vertical"
      >
        <Select
          showSearch
          data-cy="production-line-test-zone"
          ref={testZoneElemet}
          mode="multiple"
          options={getTestZonesByPlant({
            testZones: allTestZones,
            plantName: plant ? plant.value : null,
          })}
          onChange={onTestZoneChange}
          value={testZones}
          isDisabled={!plant}
          placeholder={intl.formatMessage({
            id: 'productionLineDashboard.dashboardControl.selectTestZonePlaceholder',
          })}
          filterOption={(input, option) => {
            return option.label.toLowerCase().includes(input.toLowerCase())
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id="productionLineDashboard.dashboardControl.maxTime" />
        }
        className="production-line-time"
        layout="vertical"
      >
        <Input
          data-cy="production-line-test-time"
          value={timeAfterTest.label}
          onChange={handleInputChange}
          defaultValue={DEFAULT_TIME_AFTER_TEST.label}
        />
      </Form.Item>
      <Button
        data-cy="production-line-apply-button"
        type="primary"
        disabled={testZones.length === 0 || timeAfterTest.value === 0}
        onClick={onApplyClick}
      >
        <FormattedMessage id="productionLineDashboard.dashboardControl.apply" />
      </Button>
    </Flex>
  )
}

export default injectIntl(DashboardControl)
