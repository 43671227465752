import {
  DARK_MODE,
  WHITE_COLOR,
  CHARCOAL_NAVY_COLOR,
  COMET_COLOR,
  CHARCOAL_BLUE_COLOR,
  LIGHT_GRAY_COLOR,
  GUNMETAL_COLOR,
  TRANSPARENT,
  AZURE_RADIANCE_COLOR,
  BRAND_BLUE,
  BRIGHT_GREY_COLOR,
} from './constants'
import { BLACK_COLOR } from './constants/colors'

export const themeComponentCreator = (theme) => {
  if (theme == DARK_MODE) {
    return {
      Card: {
        colorBgContainer: CHARCOAL_BLUE_COLOR,
        colorBorderSecondary: COMET_COLOR,
      },
      Popover: {
        zIndexPopup: 8000,
        colorBgElevated: CHARCOAL_NAVY_COLOR,
      },
      Pagination: {
        itemActiveBg: WHITE_COLOR,
        colorBgContainer: '',
      },
      Layout: {
        headerBg: CHARCOAL_NAVY_COLOR,
      },
      Button: {
        colorBgContainer: CHARCOAL_NAVY_COLOR,
        defaultHoverBg: CHARCOAL_NAVY_COLOR,
        colorPrimary: WHITE_COLOR,
        primaryColor: CHARCOAL_NAVY_COLOR,
        colorPrimaryHover: LIGHT_GRAY_COLOR,
      },
      Radio: {
        buttonBg: CHARCOAL_NAVY_COLOR,
        buttonSolidCheckedBg: WHITE_COLOR,
        buttonSolidCheckedColor: COMET_COLOR,
        colorPrimaryHover: LIGHT_GRAY_COLOR,
      },
      Table: {
        colorBgContainer: CHARCOAL_NAVY_COLOR,
        colorBorderSecondary: COMET_COLOR,
        headerBg: LIGHT_GRAY_COLOR,
        headerColor: CHARCOAL_NAVY_COLOR,
        headerSplitColor: TRANSPARENT,
      },
      Select: {
        colorBgContainer: CHARCOAL_NAVY_COLOR,
        colorBgElevated: CHARCOAL_NAVY_COLOR,
      },
      Input: {
        colorBgContainer: CHARCOAL_NAVY_COLOR,
      },
      Modal: {
        contentBg: GUNMETAL_COLOR,
        headerBg: GUNMETAL_COLOR,
      },
      Tabs: {
        colorBgContainer: WHITE_COLOR,
        itemSelectedColor: CHARCOAL_NAVY_COLOR,
        itemHoverColor: LIGHT_GRAY_COLOR,
        horizontalMargin: '0 0 10px 0',
      },
      Carousel: {
        colorBgContainer: WHITE_COLOR,
      },
      Drawer: {
        colorBgElevated: CHARCOAL_NAVY_COLOR,
      },
      Tooltip: {
        colorBgSpotlight: CHARCOAL_BLUE_COLOR,
      },
    }
  } else {
    return {
      Popover: {
        zIndexPopup: 8000,
      },
      Card: {
        colorBgContainer: WHITE_COLOR,
        colorBorderSecondary: LIGHT_GRAY_COLOR,
      },
      Layout: {
        headerBg: WHITE_COLOR,
      },
      Table: {
        headerBg: LIGHT_GRAY_COLOR,
        headerColor: CHARCOAL_NAVY_COLOR,
        headerSplitColor: TRANSPARENT,
      },
      Tabs: {
        colorBgContainer: AZURE_RADIANCE_COLOR,
        itemSelectedColor: WHITE_COLOR,
        horizontalMargin: '0 0 10px 0',
      },
      Button: {
        colorPrimary: BRAND_BLUE,
      },
      Radio: {
        colorPrimary: BRAND_BLUE,
      },
      Carousel: {
        colorBgContainer: BLACK_COLOR,
      },
      Tooltip: {
        colorBgSpotlight: BRIGHT_GREY_COLOR,
        colorTextLightSolid: BLACK_COLOR,
      },
    }
  }
}
