import {
  MODAL_CONFIRM_VERIFY_COMPONENT,
  MAIN_NAMESPACE,
  PINOUT_VIEW,
  VEHICLE_VIEW,
} from '../constants'
import { addNavigationTab, removeNavigationTab } from '../actions/tab-actions'
import { unsetModal } from '../actions/modal-actions'
import {
  selectElement as selectElementAction,
  setVerifiedComponents,
  appendVerifiedComponent,
  removeVerifiedComponent,
} from '../actions/component-actions'
import {
  markComponentAsVerified,
  unmarkComponentAsVerified,
  fetchVerifiedComponents,
} from '../api/logging-api'
import { addComponentProblem } from '../api/controller-api'
import { getVin } from '../selectors/vehicle-test'
import { getSelectedComponentId } from '../selectors/component'

export function selectElement(selectedElement, centerOnSelectedElement) {
  return function (dispatch) {
    dispatch(selectElementAction(selectedElement, centerOnSelectedElement))

    const isFuseCategory = selectedElement
      ? selectedElement.category === 'fuse'
      : false
    if (isFuseCategory) {
      dispatch(removeNavigationTab(PINOUT_VIEW, MAIN_NAMESPACE))
    } else {
      dispatch(addNavigationTab(VEHICLE_VIEW, MAIN_NAMESPACE))
      dispatch(addNavigationTab(PINOUT_VIEW, MAIN_NAMESPACE))
    }
  }
}

export function onMarkComponentAsVerified() {
  return function (dispatch, getState) {
    const vin = getVin(getState())
    const componentId = getSelectedComponentId(getState())
    return markComponentAsVerified(vin, componentId)
      .then(() => {
        dispatch(appendVerifiedComponent(componentId))
        dispatch(unsetModal(MODAL_CONFIRM_VERIFY_COMPONENT))
      })
      .catch((e) => console.error(e))
  }
}

export function onUnmarkComponentAsVerified() {
  return function (dispatch, getState) {
    const vin = getVin(getState())
    const componentId = getSelectedComponentId(getState())
    return unmarkComponentAsVerified(vin, componentId)
      .then(() => dispatch(removeVerifiedComponent(componentId)))
      .catch((e) => console.error(e))
  }
}

export function loadVerifiedComponents() {
  return function (dispatch, getState) {
    const vin = getVin(getState())
    return fetchVerifiedComponents(vin).then(({ data }) =>
      dispatch(setVerifiedComponents(data)),
    )
  }
}

export function logComponentProblem(options) {
  return function (dispatch) {
    return addComponentProblem(options)
      .then((resp) => {
        dispatch(onUnmarkComponentAsVerified())
        dispatch(unsetModal())
        return resp
      })
      .catch((e) => console.error(e))
  }
}
