import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Popover, Card, Flex, ConfigProvider, Typography } from 'antd'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import ComponentFaultsHistoryList from '../ComponentFaultsHistoryList'
import { getElementName } from '../../helpers/element'
import { getComponentListItemId } from '../../helpers/utils'
import { ConnectorColorTag } from '../ConnectorColorTag'

import IconHev from '../Icons/IconHev'
import StateButtons from '../StateButton/StateButtons'
import IconFail from '../Icons/IconFail'
import IconOk from '../Icons/IconOk'

import { createDtcList } from '../../selectors'
import { selectElement } from '../../thunks/component'
import { highlightDtcs, toggleOffDtc } from '../../actions/dtc-actions'
import { COMPONENT_CATEGORY_HIGH_VOLTAGE } from '../../constants'
import {
  hideComponentPopover,
  showComponentPopover,
} from '../../actions/component-actions'

import { InfoCircleOutlined } from '@ant-design/icons'
import { AZURE_RADIANCE_COLOR, SKY_BLUE_COLOR } from '../../constants/colors'
import { selectNavigationTab } from '../../actions/tab-actions'
import { VEHICLE_VIEW } from '../../constants'

const { Text } = Typography

const propTypes = {
  component: PropTypes.object.isRequired,
  status: PropTypes.string,
  selected: PropTypes.bool,
  indicate: PropTypes.bool,
}

const ComponentListItem = (props) => {
  const theme = useSelector((state) => state.app.theme, shallowEqual)
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )
  const isComponentPopoverOpen = useSelector(
    (state) => state.componentState.popover,
    shallowEqual,
  )
  const dispatch = useDispatch()

  const { status, selected, indicate, component, selectedRef } = props
  const faulty = status !== undefined
  const componentId = component?.id
  const dtcs = useSelector((state) => createDtcList(state))
  const componentFaultsHistory = useSelector(
    (state) => state.loggingState.historiesForComponents[componentId],
  )
  const showComponentHistoryIcon = !isEmpty(componentFaultsHistory)
  const verifiedComponents = useSelector(
    (state) => state.componentState.verifiedComponents || [],
  )
  const isComponentVerified =
    verifiedComponents.findIndex((cid) => cid === componentId) !== -1

  const onComponentClick = () => {
    dispatch(selectElement(component, true))
    if (component.category == 'not_electrical') {
      dispatch(selectNavigationTab(VEHICLE_VIEW, 'main'))
    }
    dispatch(toggleOffDtc())
    dispatch(
      highlightDtcs(
        dtcs.filter((dtc) => dtc.components && dtc.components.has(componentId)),
      ),
    )
  }

  const highVoltageIcon =
    component?.category === COMPONENT_CATEGORY_HIGH_VOLTAGE ? <IconHev /> : null

  let okFailedIcon = null

  if (faulty) {
    okFailedIcon = <IconFail enableOutline={selected || indicate} />
  } else if (isComponentVerified) {
    okFailedIcon = <IconOk enableOutline={selected || indicate} />
  }

  return (
    <>
      {!selected && (
        <Card
          onClick={() => onComponentClick()}
          id={getComponentListItemId(componentId)}
          className={classNames('component-list-item', {
            indicate,
            verified: isComponentVerified,
            faulty,
          })}
          data-testid="dtc-group-item"
        >
          <Flex
            className="component-list-item-title"
            vertical={false}
            gap="small"
            align="center"
          >
            {highVoltageIcon}
            <span>{getElementName(component)}</span>
            {okFailedIcon}
          </Flex>
          <div>
            <ConnectorColorTag colorCode={component?.color} />
            <Text
              style={{ width: 210 }}
              ellipsis={{ tooltip: component.description }}
            >
              {component.description}
            </Text>
          </div>
        </Card>
      )}
      {selected && (
        <ConfigProvider
          theme={{
            components: {
              Card: {
                colorBgContainer:
                  theme == 'dark' ? AZURE_RADIANCE_COLOR : SKY_BLUE_COLOR,
              },
            },
          }}
        >
          <Card
            className={classNames('component-item-container-card', {
              verified: isComponentVerified,
              faulty,
            })}
            ref={selectedRef}
          >
            <Flex
              className="component-item-container-card-header"
              vertical={false}
              align="center"
              justify="space-between"
            >
              <Flex vertical={false} gap="small" align="center">
                {highVoltageIcon}
                <span>{getElementName(component)}</span>
                {okFailedIcon}
              </Flex>
              {showComponentHistoryIcon && (
                <Popover
                  open={selected && isComponentPopoverOpen}
                  id={`component-history-popover-${componentId}`}
                  trigger="click"
                  placement="right"
                  content={() => (
                    <ComponentFaultsHistoryList
                      componentFaultsHistory={componentFaultsHistory}
                    />
                  )}
                  overlayStyle={{
                    width: '40vw',
                    zIndex: '5',
                  }}
                  onOpenChange={() =>
                    isComponentPopoverOpen
                      ? dispatch(hideComponentPopover())
                      : dispatch(showComponentPopover())
                  }
                >
                  <InfoCircleOutlined />
                </Popover>
              )}
            </Flex>
            <div>
              <ConnectorColorTag colorCode={component?.color} />
              <Text
                style={{ width: 210 }}
                ellipsis={{ tooltip: component.description }}
              >
                {component.description}
              </Text>
              <div className="component-fault-verify-buttons-container">
                {selected && !isEphemeralVehicleTest && <StateButtons />}
              </div>
            </div>
          </Card>
        </ConfigProvider>
      )}
    </>
  )
}

ComponentListItem.propTypes = propTypes

export default ComponentListItem
