import axiosWrapper, { InterceptorOptions } from './axios-wrapper'
import store from '../store'

import { createElementsAndNodeMapFromGraph } from '../helpers/utils'

import {
  setDemoPayloads,
  setComponentsForModel,
} from '../actions/component-actions'
import { setHarnessesForModel } from '../actions/harness-actions'
import { setPinDestination } from '../actions/pin-actions'
import { updateCircuitState, getRepairHistory } from './logging-api'
import { locale } from '../Locale'
import { apiGetTerminology } from './terminology'

const controller = () =>
  axiosWrapper.create(
    {
      baseURL: '/tracer/',
    },
    InterceptorOptions.defaultErrorInterceptor,
  )

export function getPinDestination(vin, componentId, pin) {
  controller()
    .get(`harness/vin/${vin}/component/${componentId}/pin/${pin}/destination`)
    .then((response) => {
      const codes = response.data.graph.nodes.map((n) => n.id)
      apiGetTerminology({ vin, codes, language: locale }).then((terms) => {
        response.data.graph.nodes.forEach((node, idx) => {
          const key = node.id
          if (!!key && !!terms[key] && !!terms[key]['description']) {
            response.data.graph.nodes[idx]['description'] =
              terms[key]['description']
          }
        })
        createElementsAndNodeMapFromGraph(response.data.graph).then((obj) => {
          store.dispatch(setPinDestination(obj.elements))
        })
      })
    })
    .catch((error) => {
      // TODO handle pin dest errors
      store.dispatch(setPinDestination({ nodes: [], edges: [] }))
    })
}

export function getComponentsForModel(modelId) {
  controller()
    .get(`/collection/components/model_id/${modelId}?datetime=${Date.now()}`)
    .then((response) => {
      store.dispatch(setComponentsForModel(response.data))
    })
}

export function getHarnessesForModel(modelId) {
  controller()
    .get(`/collection/harnesses/model_id/${modelId}?datetime=${Date.now()}`)
    .then((response) => {
      store.dispatch(setHarnessesForModel(response.data))
    })
}

export function updateComponentProblem({
  id,
  annotation = null,
  vehicleTestResultId = null,
  componentId,
  makeModelId,
  vin,
  isProblem,
  pinNumber = null,
  reason = null,
  dtcs,
}) {
  return logComponentProblem({
    id,
    annotation,
    vehicleTestResultId,
    componentId,
    makeModelId,
    vin,
    isProblem,
    pinNumber,
    reason,
    dtcs,
  })
}

export function addComponentProblem({
  annotation,
  vehicleTestResultId,
  componentId,
  dtcs,
  isProblem,
  makeModelId,
  pinNumber,
  reason,
  vin,
}) {
  return logComponentProblem({
    annotation,
    vehicleTestResultId,
    componentId,
    dtcs,
    isProblem,
    makeModelId,
    pinNumber,
    reason,
    vin,
  })
}

export function logComponentProblem(params) {
  const { id, makeModelId, vin } = params
  const method = id ? 'put' : 'post'

  return controller()
    [method]('/log/component_problem', params)
    .then((response) => {
      updateCircuitState(makeModelId, vin)
      getRepairHistory(makeModelId, vin)
      return response.data
    })
    .catch((error) => {
      console.error('ERROR:', error)
    })
}

export function removeComponentProblem(params) {
  const { problemId, makeModelId, vin } = params

  return controller()
    .delete(`/collection/repair-history/id/${problemId}`)
    .then(() => {
      updateCircuitState(makeModelId, vin)
      getRepairHistory(makeModelId, vin)
    })
    .catch((error) => {
      console.error('ERROR:', error)
    })
}

export function updateOtherProblem({
  id,
  annotation,
  vehicleTestResultId,
  makeModelId,
  vin,
  reason,
  dtcs,
}) {
  logOtherProblem({
    id,
    annotation,
    vehicleTestResultId,
    makeModelId,
    vin,
    reason,
    dtcs,
  })
}

export function addOtherProblem({
  annotation,
  vehicleTestResultId,
  makeModelId,
  vin,
  reason,
  dtcs,
}) {
  return logOtherProblem({
    annotation,
    vehicleTestResultId,
    makeModelId,
    vin,
    reason,
    dtcs,
  })
}

export function logOtherProblem(params) {
  const { id, makeModelId, vin } = params
  const method = id ? 'put' : 'post'

  return controller()
    [method]('/log/other_problem', params)
    .then((response) => {
      updateCircuitState(makeModelId, vin)
      getRepairHistory(makeModelId, vin)
    })
    .catch((error) => {
      console.error('ERROR:', error)
    })
}

export function updateHarnessProblem({
  id,
  annotation = null,
  vehicleTestResultId = null,
  harness,
  makeModelId,
  vin,
  reason,
  dtcs,
  harnessLevel,
}) {
  logHarnessProblem({
    id,
    annotation,
    vehicleTestResultId,
    harness,
    makeModelId,
    vin,
    isProblem: true,
    reason,
    dtcs,
    harnessLevel,
  })
}

export function addHarnessProblem({
  annotation = null,
  vehicleTestResultId = null,
  harness,
  makeModelId,
  vin,
  isProblem,
  reason = null,
  harnessLevel,
  dtcs,
}) {
  return logHarnessProblem({
    annotation,
    vehicleTestResultId,
    harness,
    makeModelId,
    vin,
    isProblem,
    reason,
    dtcs,
    harnessLevel,
  })
}

export function logHarnessProblem(params) {
  const { id, makeModelId, vin } = params
  const method = id ? 'put' : 'post'

  return controller()
    [method]('/log/harness_problem', params)
    .then((response) => {
      updateCircuitState(makeModelId, vin)
      getRepairHistory(makeModelId, vin)
    })
    .catch((error) => {
      console.error('ERROR:', error)
    })
}

export function logDeviceProblem(params) {
  const { id, makeModelId, vin } = params
  const method = id ? 'put' : 'post'

  return controller()
    [method]('/log/device_problem', params)
    .then((response) => {
      updateCircuitState(makeModelId, vin)
      getRepairHistory(makeModelId, vin)
    })
    .catch((error) => {
      console.error('ERROR:', error)
    })
}

export function getDemoPayloads() {
  return controller()
    .get(`/demo/data?datetime=${Date.now()}`)
    .then((response) => {
      store.dispatch(setDemoPayloads(response.data))
    })
    .catch((error) => {
      console.error(error)
    })
}
