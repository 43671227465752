import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import history from '../history'
import { useUserPlant, useUserInfo } from '../hooks/User'
import { unsetModal } from '../actions/modal-actions'
import { GRAY_COLOR } from '../constants'
import { Modal, Radio, Button, Space, Flex } from 'antd'

const SelectStationAlert = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [storedStationAlertFilter, setStoredStationAlertFilter] =
    useLocalStorage('station-alert', 'send,skip')
  const [, , remove] = useLocalStorage('reports-query')
  const [stationAlertFilter, setStationAlertFilter] = useState(
    storedStationAlertFilter,
  )

  const options = [
    {
      value: 'send,skip',
      label: intl.formatMessage({ id: 'selectStationAlert.allFaultsLabel' }),
    },
    {
      value: 'send',
      label: intl.formatMessage({ id: 'selectStationAlert.sendOnlyLabel' }),
    },
  ]

  const onSubmitClick = () => {
    remove()
    setStoredStationAlertFilter(stationAlertFilter)
    closeModal()
    if (location.pathname == '/reports') history.go(0)
  }

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="selectStationAlert.title" />}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button
          type="primary"
          onClick={onSubmitClick}
          disabled={setStationAlertFilter === null}
          data-testid="submit-station-alert"
        >
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
      centered={true}
    >
      <Flex gap="middle" vertical={true}>
        <div>
          <FormattedMessage id="selectStationAlert.leadingText" />
        </div>
        <Radio.Group
          onChange={(e) => setStationAlertFilter(e.target.value)}
          value={stationAlertFilter}
        >
          <Space direction="vertical">
            {options.map((availableStationAlertFilter, index) => {
              return (
                <Radio
                  value={availableStationAlertFilter.value}
                  data-testid={`station-alert-filter-${index}`}
                >
                  {availableStationAlertFilter.label}
                </Radio>
              )
            })}
          </Space>
        </Radio.Group>
      </Flex>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectStationAlert),
)
