import { injectIntl } from '../../node_modules/react-intl/index'
import { requireAuthentication } from '../helpers/authentication'
import { useEffect, useState } from 'react'

import { useUserPlant } from '../hooks/User'
import { IssueEditForm } from './IssueEditForm'
import { IssueSummary } from './IssueSummary'
import { useLocation } from 'react-router-dom'

import { Flex, ConfigProvider, theme as antdTheme } from 'antd'
import {
  BRAND_BLUE,
  WHITE_COLOR,
  AZURE_RADIANCE_COLOR,
  LIGHT_GRAY_COLOR,
  TRANSPARENT,
  BLACK_COLOR,
} from '../constants'

import './styles.scss'

const getFaultIdentifier = (fault) => {
  let identifier = null
  if (fault['type'] == 'component') {
    identifier = fault['component_id']
  } else if (fault['type'] == 'harness') {
    identifier = fault['harness']
  } else if (fault['type'] == 'device') {
    identifier = fault['device_name']
  }
  return identifier
}

const storageKey = 'incident-mgmt-faults'

const getFaultFromURL = (faultId) => {
  let fault = null
  if (faultId) {
    const faultString = localStorage.getItem(storageKey)
    fault = JSON.parse(faultString)
  }
  return fault
}

const emptyIssue = {
  component_id: null, // ID of component, harness, or device
  fault_type: null,
  fault_short_code: null,
  plant_id: null,
  problem_log_id: null, // id from problem_logs.id
  description: null,
  problem_description: {
    what: null,
    when: null,
    where: null,
    who: null,
    which: null,
  },
  possible_root_causes: {
    man: null,
    machine: null,
    method: null,
    material: null,
    design: null,
  },
  direct_root_causes: {
    five_whys: [null, null, null, null, null],
    direct_root_cause: null,
    detection_root_cause: null,
    systemic_root_cause: null,
  },
  actions: [
    {
      assignee: null,
      type: null,
      description: null,
      start_date: null,
      due_date: null,
      close_date: null,
      status: 'open',
    },
  ],
  status: 'open',
}

// Gets the issue we're editing based on the passed-in fault
const getIssue = (fault, plant_id) => {
  if (!!fault) {
    if (!!fault.attachedIssue) {
      // the fault already has a linked issue
      return fault.attachedIssue
    }
    // We need to create the issue with the fault info added
    const issue = structuredClone(emptyIssue)
    issue['fault_type'] = fault['problem_code'] //yeah this is a bit weird
    const identifier = getFaultIdentifier(fault)
    issue['component_id'] = identifier
    issue['plant_id'] = plant_id
    issue['problem_log_id'] = fault['id']
    issue['fault_short_code'] = fault['short_code']

    let desc_prfx = ''
    if (fault.reason) {
      desc_prfx = fault.reason
    }

    let desc_sfx = ''

    if (!!fault.component_description) {
      desc_sfx = fault.component_description
    } else if (fault.device_name) {
      desc_sfx = fault.device_name
    } else if (fault.description) {
      desc_sfx = fault.description
    }
    issue['description'] = `${desc_prfx} - ${desc_sfx}`
    return issue
  }
  return null
}

// Gets the possibly related issues to the fault
const getPotentialIssues = (fault) => {
  if (fault) {
    return fault['relatedIssues']
  }
  return null
}

// this page will be opened with a base64-encoded fault as an
// optional "fault" url query param - if it exists we're here from an
// "add issue" or "n issues" button, if not we're here from the home page
function IncidentMgmtPage({ intl }) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const faultParam = searchParams.get('fault')

  const [userPlant] = useUserPlant()
  const { defaultAlgorithm } = antdTheme
  // if fault is null we're just here from the main page,
  // if it's populated we're here from an add/edit issue button
  const [faultInUrl, setFaultInUrl] = useState(false)
  const [fault, setFault] = useState(null)
  // if issue is null we are not editing an issue yet but either
  // potentially adding a case to an issue or just viewing from the
  // main page. If issue is populated we're adding/editing it
  const [issue, setIssue] = useState(null)
  // potentialIssues are all of the ones that the fault may be linked
  // to where we might add a case
  const [potentialIssues, updatePotentialIssues] = useState(null)

  useEffect(() => {
    const faultTemp = getFaultFromURL(faultParam)
    const potentialIssuesTemp = getPotentialIssues(faultTemp)
    setFaultInUrl(faultParam ? faultParam : false)
    setFault(faultTemp)
    setIssue(getIssue(faultTemp, userPlant))
    updatePotentialIssues(potentialIssuesTemp)
  }, [faultParam])

  const isEditingIssue = () => {
    return faultInUrl && (!potentialIssues || potentialIssues.length === 0)
  }

  const isViewingIssue = () => {
    return !faultInUrl || (!!potentialIssues && potentialIssues.length > 0)
  }

  return (
    <Flex vertical={false} id="incident-mgmt-page" gap={20}>
      <ConfigProvider
        theme={{
          algorithm: defaultAlgorithm,
          components: {
            Collapse: {
              headerBg: '#f2f2f2',
            },
            Input: {
              colorBgContainer: WHITE_COLOR,
            },
            Button: {
              colorBgContainer: WHITE_COLOR,
              defaultHoverBg: WHITE_COLOR,
              colorPrimary: BRAND_BLUE,
              primaryColor: WHITE_COLOR,
              colorPrimaryHover: '#4096ff',
            },
            Select: {
              colorBgContainer: WHITE_COLOR,
              colorBgElevated: WHITE_COLOR,
            },
            Tabs: {
              colorBgContainer: AZURE_RADIANCE_COLOR,
              itemSelectedColor: WHITE_COLOR,
              horizontalMargin: '0 0 10px 0',
            },
            Card: {
              colorBgContainer: WHITE_COLOR,
              colorBorderSecondary: '#D9D9D9',
              headerBg: '#f2f2f2',
            },
            Table: {
              headerBg: LIGHT_GRAY_COLOR,
              colorBgContainer: WHITE_COLOR,
              colorBorderSecondary: LIGHT_GRAY_COLOR,
              headerColor: BLACK_COLOR,
              headerSplitColor: TRANSPARENT,
            },
          },
          token: {
            colorText: BLACK_COLOR,
          },
        }}
      >
        <div className="incident-mgmt-left-container">
          {isEditingIssue() && ( // we want to be able to edit
            <IssueEditForm
              issue={issue}
              setIssue={setIssue}
              fault={fault}
              intl={intl}
            />
          )}
          {isViewingIssue() && ( // we just want to see the details
            <IssueEditForm
              fault={fault}
              showFaultDetails={true}
              issue={issue}
              setIssue={setIssue}
              orientation="vertical"
              readOnly={true}
              intl={intl}
            />
          )}
        </div>
        <div className="incident-mgmt-right-container">
          <IssueSummary
            fault={fault}
            issue={issue}
            intl={intl}
            setIssue={setIssue}
            potentialIssues={potentialIssues}
            setFault={setFault}
            showList={isViewingIssue()}
          />
        </div>
      </ConfigProvider>
    </Flex>
  )
}

export default requireAuthentication(injectIntl(IncidentMgmtPage))
