import React from 'react'
import { Modal, Tag, Skeleton, Table } from 'antd'
import { useRelatedDTCs, useRelatedCircuits } from '../hooks/CircuitDTCs'
import {
  useCircuitDTCsContext,
  useCircuitDTCsDispatch,
} from './CircuitDTCsContext'
import { FormattedMessage } from 'react-intl'

export const RelatedDTCsModal = () => {
  const context = useCircuitDTCsContext()
  const dispatch = useCircuitDTCsDispatch()

  const { isLoading, dtcs } = useRelatedDTCs({
    makeModelId: context.makeModelId,
    circuits: [context.circuit],
  })

  return (
    <Modal
      data-testid="related-dtcs-modal"
      open={context.makeModelId !== null && context.circuit != null}
      onOk={() => dispatch.reset()}
      onCancel={() => dispatch.reset()}
      cancelButtonProps={{ hidden: true }}
      title={
        <FormattedMessage
          id="circuitDTCs.relatedDTCsModal"
          values={{
            circuit: context.circuit,
          }}
        />
      }
      width={800}
    >
      <DTCTable dtcs={dtcs} isLoading={isLoading} />
    </Modal>
  )
}

export const RelatedCircuitsModal = () => {
  const context = useCircuitDTCsContext()
  const dispatch = useCircuitDTCsDispatch()

  const { isLoading, circuits } = useRelatedCircuits({
    makeModelId: context.makeModelId,
    dtcs: [context.dtc],
  })

  return (
    <Modal
      data-testid="related-circuits-modal"
      open={
        context.makeModelId !== null &&
        context.dtc.dtc != null &&
        context.dtc.acronym != null
      }
      onOk={() => dispatch.reset()}
      onCancel={() => dispatch.reset()}
      cancelButtonProps={{ hidden: true }}
      title={
        <FormattedMessage
          id="circuitDTCs.relatedCircuitsModal"
          values={{
            dtc: context.dtc.dtc,
            acronym: context.dtc.acronym,
          }}
        />
      }
      width={800}
    >
      <CircuitTable circuits={circuits} isLoading={isLoading} />
    </Modal>
  )
}

const CircuitTable = ({ circuits, isLoading }) => {
  const columns = [
    {
      title: <FormattedMessage id="circuitDTCs.circuit" />,
      dataIndex: 'circuit',
      width: 150,
    },
    /*{ // Will be uncomment when new column source is introduced.
      title: <FormattedMessage id="circuitDTCs.shortDescription" />,
      dataIndex: 'dtc_short_description',
    },*/
  ]
  return (
    <Table
      loading={isLoading}
      dataSource={circuits}
      columns={columns}
      rowKey={(record) =>
        `${record.dtc}-${record.acronym}-${record.makeModelId}`
      }
      data-testid="related-circuits-results-table"
      pagination={false}
      scroll={{
        y: 300,
      }}
    />
  )
}

const DTCTable = ({ dtcs, isLoading }) => {
  const columns = [
    {
      title: <FormattedMessage id="circuitDTCs.dtc" />,
      dataIndex: 'dtc',
      width: 150,
    },
    {
      title: <FormattedMessage id="circuitDTCs.acronym" />,
      dataIndex: 'acronym',
      width: 150,
    },
    {
      title: <FormattedMessage id="circuitDTCs.shortDescription" />,
      dataIndex: 'dtc_short_description',
    },
  ]
  return (
    <Table
      loading={isLoading}
      dataSource={dtcs}
      columns={columns}
      rowKey={(record) =>
        `${record.dtc}-${record.acronym}-${record.makeModelId}`
      }
      data-testid="related-dtcs-results-table"
      pagination={false}
      scroll={{
        y: 300,
      }}
    />
  )
}
