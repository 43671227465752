import { useUserPlant } from '../hooks/User'
import { useShifts } from '../hooks/Shifts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import objectSupport from 'dayjs/plugin/objectSupport'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

dayjs.extend(objectSupport)
dayjs.extend(utc)

export const IssueExportButton = ({ issues, problemLogs, intl }) => {
  const [userPlant] = useUserPlant()
  const { isLoading: shiftsIsLoading, shifts: plantShifts } = useShifts({
    plant_name: userPlant,
  })

  const [readyToExport, setReadyToExport] = useState(false)
  useEffect(() => {
    const ready = !shiftsIsLoading && !isEmpty(problemLogs) && !isEmpty(issues)
    setReadyToExport(ready)
  }, [issues, problemLogs, shiftsIsLoading])

  const setTimeToDate = (date, time) => {
    return date.clone().set({
      hour: time.hour(),
      minute: time.minute(),
      second: 0,
      millisecond: 0,
    })
  }

  // takes string of problem's created_at and returns shift name
  // assumes all are utc
  const getShiftName = (probTime) => {
    const probTm = dayjs.utc(probTime)
    for (let i = 0; i < plantShifts.length; i++) {
      const startHr = setTimeToDate(
        probTm,
        dayjs.utc(plantShifts[i].starts_at, 'HH:mm'),
      )
      const endHr = setTimeToDate(
        probTm,
        dayjs.utc(plantShifts[i].ends_at, 'HH:mm'),
      )
      if (startHr < endHr) {
        // shift doesn't wrap, simple comparison
        if (startHr <= probTm && probTm <= endHr) {
          return plantShifts[i].name
        }
      } else {
        // shift wraps
        const dayStart = probTm.startOf('day')
        const dayEnd = probTm.endOf('day')
        if (
          (dayStart <= probTm && probTm <= endHr) ||
          (startHr <= probTm && dayEnd)
        ) {
          return plantShifts[i].name
        }
      }
    }
  }

  const createExportData = () => {
    const issueIdKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.issueId',
      defaultMessage: 'Issue ID',
    })
    const vinKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.vins',
      defaultMessage: 'VINs',
    })
    const dateKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.date',
      defaultMessage: 'Date',
    })
    const modelKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.model',
      defaultMessage: 'Model',
    })
    const shiftKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.shifts',
      defaultMessage: 'Shifts',
    })
    const defectKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.defects',
      defaultMessage: 'Defects',
    })
    const testZoneKey = intl.formatMessage({
      id: 'incidentMgmt.csvExportHeaders.testZones',
      defaultMessage: 'Test Zones',
    })
    const res = []
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i]
      const issueID = issue.id
      const issueDate = issue.created_at
      const probLogs = problemLogs[issueID]
      for (let j = 0; j < probLogs.length; j++) {
        let data = {}
        data[issueIdKey] = issueID
        data[dateKey] = issueDate
        const probLog = probLogs[j]
        data[vinKey] = probLog.vin
        data[modelKey] = probLog.model
        data[defectKey] = [probLog.reason]
        data[shiftKey] = getShiftName(probLog.created_at)
        data[testZoneKey] = probLog.hardware_location_id
        res.push(data)
      }
    }
    return res
  }

  const exportIssues = () => {
    const data = createExportData()
    const dataString = Papa.unparse(data)
    const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, 'issues-report.csv')
  }

  return (
    <Button
      disabled={!readyToExport}
      onClick={() => exportIssues()}
      icon={<DownloadOutlined />}
    >
      {intl.formatMessage({
        id: 'incidentMgmt.csvExport.exportLabel',
        defaultMessage: 'Export',
      })}
    </Button>
  )
}
