import { useMemo } from 'react'
import { Table } from 'antd'
import dayjs from 'dayjs'

export const ProblemList = ({ problems }) => {
  const columns = useMemo(
    () => [
      {
        title: 'VIN',
        render: (record) => record.vin,
      },
      {
        title: 'Timestamp',
        render: (record) => dayjs(record.created_at).format('MM/DD/YYYY h:mma'),
      },
      {
        title: 'Notes',
        render: (record) => record.note,
        ellipsis: true,
      },
      {
        title: 'UserID',
        render: (record) => record.created_by,
        ellipsis: true,
      },
    ],
    [problems],
  )
  return problems.length > 0 ? (
    <Table
      columns={columns}
      dataSource={problems}
      id="problem-list"
      pagination={{
        pageSize: 3,
        hideOnSinglePage: true,
      }}
    />
  ) : (
    ''
  )
}
