import { useQuery } from 'react-query'
import { apiGetFaults } from '../api/faults'
import { fetchAttachedIssue, fetchRelatedIssues } from '../api/issues'

export const FAULT_REPORT_QUERY_KEY = 'faults'

export const useFaults = ({
  makeModelIds,
  stationIds,
  start_at,
  end_at,
  station_alerts,
  plants,
  zones,
  teams,
  department,
  testZones,
}) => {
  const {
    status,
    data: faults,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [
      FAULT_REPORT_QUERY_KEY,
      makeModelIds,
      stationIds,
      start_at,
      end_at,
      station_alerts,
      plants,
      zones,
      teams,
      department,
      testZones,
    ],
    async () => {
      const result = await apiGetFaults({
        makeModelIds,
        stationIds,
        start_at,
        end_at,
        station_alerts,
        plants,
        zones,
        teams,
        department,
        testZones,
      })

      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!start_at && !!end_at,
    },
  )
  return {
    status,
    faults,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
