// this is the actions list component
import { useEffect, useState } from 'react'
import { Button, Select, Flex } from 'antd'
import { ActionCard } from './ActionCard'

const emptyAction = {
  assignee: null,
  description: null,
  type: null,
  start_date: null,
  due_date: null,
  close_date: null,
  status: 'open',
}

const getActions = (issue) => {
  if (!!issue && !!issue.actions) {
    return issue.actions
  }
  return [structuredClone(emptyAction)]
}

export const Actions = ({ issue, setIssue, readOnly, intl }) => {
  const [actions, setActions] = useState(getActions(issue))

  useEffect(() => {
    setActions(getActions(issue))
  }, [issue])

  const updateAction = (newAction, idx) => {
    const newActions = structuredClone(actions)
    if (newAction === null) {
      newActions.splice(idx, 1)
    } else {
      newActions[idx] = newAction
    }
    setActions(newActions)
    let newIssue = { ...issue, ...{ actions: newActions } }
    setIssue(newIssue)
  }

  const addNewAction = () => {
    const newActions = actions.concat([structuredClone(emptyAction)])
    setActions(newActions)
    let newIssue = { ...issue, ...{ actions: newActions } }
    setIssue(newIssue)
  }

  const statusOptions = [
    {
      label: intl.formatMessage({
        id: 'incidentMgmt.actions.optionOpen',
        defaultMessage: 'open',
      }),
      value: 'open',
    },
    {
      label: intl.formatMessage({
        id: 'incidentMgmt.actions.optionClosed',
        defaultMessage: 'closed',
      }),
      value: 'closed',
    },
  ]

  const updateIssueStatus = (value) => {
    setIssue({ ...issue, ...{ status: value } })
  }

  return (
    <Flex id="issue-actions" vertical={true} gap={10}>
      {intl.formatMessage({
        id: 'incidentMgmt.actions.issueStatus',
        defaultMessage: 'Issue Status',
      })}
      :{' '}
      <Select
        key="issue-status"
        className="status-input"
        value={issue.status || ''}
        onChange={(value) => updateIssueStatus(value)}
        disabled={readOnly}
        options={statusOptions}
      />
      {actions.map((action, idx) => {
        return (
          <ActionCard
            key={`action-card-${idx}`}
            action={action}
            idx={idx}
            updateAction={updateAction}
            readOnly={readOnly}
            intl={intl}
            issue={issue}
          />
        )
      })}
      {!readOnly && (
        <Button id="add-new-action-btn" onClick={addNewAction}>
          +{' '}
          {intl.formatMessage({
            id: 'incidentMgmt.actions.addAction',
            defaultMessage: 'Add Action',
          })}
        </Button>
      )}
    </Flex>
  )
}
