import React, { useEffect, useRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import UnsupportedBackground from '../assets/images/icons/svg/unsupported.svg'
import UnsupportedLightBackground from '../assets/images/icons/svg/unsupported-light.svg'
import { DARK_MODE } from '../constants'

const propTypes = {
  id: PropTypes.string,
  handleListItemClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  indicate: PropTypes.bool,
  height: PropTypes.number,
}

const ListItem = (props) => {
  const listRef = useRef(null)
  const theme = useSelector((state) => state.app.theme, shallowEqual)

  useEffect(() => {
    if (listRef.current && props.unsupported) {
      listRef.current.style.backgroundImage =
        theme == DARK_MODE
          ? `url(${UnsupportedBackground})`
          : `url(${UnsupportedLightBackground})`
    }
  }, [theme, listRef.current])

  const faulty = props.status !== undefined

  const listItemClassNames = classNames('collapsible-list-item', {
    active: props.selected,
    verified: props.verified,
    indicated: props.indicate,
    misconfigured: props.misconfigured,
    faulty,
    disabled: props.disabled,
    unsupported: props.unsupported,
  })

  const onClickHandler = () => {
    if (props.disabled) {
      return false
    }
    props.handleListItemClick && props.handleListItemClick(props.item)
  }

  return (
    <div
      aria-hidden="true"
      id={props.id}
      className={listItemClassNames}
      onClick={onClickHandler}
      data-testid="dtc-group-item"
      ref={listRef}
    >
      {props.children}
    </div>
  )
}

ListItem.propTypes = propTypes

export default ListItem
