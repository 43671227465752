import { useEffect, useState } from 'react'
import { Input, Switch, Select, Flex } from 'antd'
import { useGetIssues } from '../hooks/Issues'

const emptyDirectRootCauses = {
  five_whys: [null, null, null, null, null],
  direct_root_cause: null,
  systemic_root_cause: null,
  detection_root_cause: null,
}

const getDirectRootCauses = (issue) => {
  if (!!issue && !!issue.direct_root_causes) {
    return issue.direct_root_causes
  }
  return structuredClone(emptyDirectRootCauses)
}

// This is the "actual identified root cause(s)" component
export const DirectRootCauses = ({ issue, setIssue, readOnly, intl }) => {
  const [directRootCauses, setDirectRootCauses] = useState(
    getDirectRootCauses(issue),
  )

  useEffect(() => {
    setDirectRootCauses(getDirectRootCauses(issue))
  }, [issue])

  const [systemicEnabled, setSystemicEnabled] = useState(false)
  useEffect(() => {
    if (!!directRootCauses) {
      setSystemicEnabled(!!directRootCauses.systemic_root_cause)
    }
  }, [directRootCauses])

  const { issues } = useGetIssues()
  const [systemicOpts, setSystemicOpts] = useState([])
  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = []
    issues.forEach((iss) => {
      const rootCauses = iss.direct_root_causes
      if (!!rootCauses.systemic_root_cause) {
        opts.push(rootCauses.systemic_root_cause)
      }
    })
    const allOpts = [...new Set(opts)]
    setSystemicOpts(
      allOpts
        .filter((opt) => !!opt)
        .map((opt) => {
          return {
            value: opt,
            label: opt,
          }
        }),
    )
  }, [issues])

  const updateIssueDirectRootCauses = (key, evt) => {
    const newDirectRootCauses = structuredClone(directRootCauses)
    const value = evt.target.value
    newDirectRootCauses[key] = value
    setDirectRootCauses(newDirectRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: newDirectRootCauses } }
    setIssue(newIssue)
  }

  const updateSystemicRootCause = (value) => {
    const newDirectRootCauses = structuredClone(directRootCauses)
    newDirectRootCauses['systemic_root_cause'] = value[0]
    setDirectRootCauses(newDirectRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: newDirectRootCauses } }
    setIssue(newIssue)
  }

  const updateFiveWhys = (i, evt) => {
    const newDirectRootCauses = structuredClone(directRootCauses)
    const value = evt.target.value
    newDirectRootCauses['five_whys'][i] = value
    setDirectRootCauses(newDirectRootCauses)
    let newIssue = { ...issue, ...{ direct_root_causes: newDirectRootCauses } }
    setIssue(newIssue)
  }

  const getWhyNum = (i) => {
    if (directRootCauses && directRootCauses['five_whys'].length > i) {
      return directRootCauses['five_whys'][i]
    }
    return null
  }

  const enterMsg = intl.formatMessage({
    id: 'incidentMgmt.directRootCauses.enter',
    defaultMessage: 'Enter',
  })
  const searchPlaceholder = intl.formatMessage({
    id: 'incidentMgmt.searchPlaceholder',
    defaultMessage: 'Enter or Select Similar',
  })

  const getFiveWhysInputs = () => {
    const inputs = []
    for (let i = 0; i < 5; i++) {
      inputs.push(
        <div className="input-and-label" key={`direct-root-causes-why-${i}`}>
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.directRootCauses.why',
              defaultMessage: 'Why',
            })}{' '}
            {i + 1}:
          </span>
          <Input
            placeholder={readOnly ? '' : enterMsg}
            onChange={(evt) => updateFiveWhys(i, evt)}
            value={getWhyNum(i)}
            disabled={readOnly}
          />
        </div>,
      )
    }
    return inputs
  }

  return (
    <Flex id="issue-direct-root-causes" vertical={true} gap={10}>
      {getFiveWhysInputs()}
      <div className="input-and-label" key={`direct-root-cause`}>
        <span className="input-label">
          {intl.formatMessage({
            id: 'incidentMgmt.directRootCauses.directRootCause',
            defaultMessage: 'Direct Root Cause',
          })}
          :
        </span>
        <Input
          placeholder={readOnly ? '' : enterMsg}
          onChange={(evt) =>
            updateIssueDirectRootCauses('direct_root_cause', evt)
          }
          value={directRootCauses['direct_root_cause']}
          disabled={readOnly}
        />
      </div>
      <div className="input-and-label" key={`detection-root-cause`}>
        <span className="input-label">
          {intl.formatMessage({
            id: 'incidentMgmt.directRootCauses.detectionRootCause',
            defaultMessage: 'Detection Root Cause',
          })}
          :
        </span>
        <Input
          placeholder={readOnly ? '' : enterMsg}
          onChange={(evt) =>
            updateIssueDirectRootCauses('detection_root_cause', evt)
          }
          value={directRootCauses['detection_root_cause']}
          disabled={readOnly}
        />
      </div>
      <Flex
        className="input-and-label systemic-input"
        key={`systemic-root-cause`}
        vertical={true}
        gap={5}
      >
        <Flex vertical={false} gap={5}>
          <span className="input-label">
            {intl.formatMessage({
              id: 'incidentMgmt.directRootCauses.systemicRootCause',
              defaultMessage: 'Systemic Root Cause',
            })}
            :
          </span>
          <Switch
            onClick={() => setSystemicEnabled(!systemicEnabled)}
            defaultChecked={systemicEnabled}
            disabled={readOnly}
          />
        </Flex>
        <Select
          mode="tags"
          placeholder={readOnly ? '' : searchPlaceholder}
          value={
            !!directRootCauses['systemic_root_cause']
              ? [directRootCauses['systemic_root_cause']]
              : []
          }
          disabled={readOnly || !systemicEnabled}
          options={systemicOpts}
          onChange={(value) => updateSystemicRootCause(value)}
          maxCount="1"
          style={{ width: '100%' }}
        />
      </Flex>
    </Flex>
  )
}
