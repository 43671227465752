export const getSubsystemCodes = (subsystems) => {
  let subsystemCodeObj = {}
  let codes = []

  subsystems.forEach((subsystem, index) => {
    codes.push(subsystem.code)
    codes.push(subsystem.system.code)

    if (!subsystemCodeObj[subsystem.code])
      subsystemCodeObj[subsystem.code] = [index]
    else subsystemCodeObj[subsystem.code].push(index)

    if (!subsystemCodeObj[subsystem.system.code])
      subsystemCodeObj[subsystem.system.code] = [index]
    else subsystemCodeObj[subsystem.system.code].push(index)
  })

  return { groups: subsystemCodeObj, codes: codes }
}

export const updateSubsystemDescription = (
  data,
  subsystems,
  subsystemGroups,
) => {
  let subsystemsCpy = [...subsystems]
  Object.entries(data).forEach(([code, data]) => {
    if (data.description) {
      subsystemGroups[code].forEach((index) => {
        if (subsystemsCpy[index].code == code) {
          subsystemsCpy[index].name = data.description
        } else {
          subsystemsCpy[index].system.name = data.description
        }
      })
    }
  })

  return subsystemsCpy
}
