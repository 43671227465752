import React from 'react'
import { Button, Spin, Badge, Flex, ConfigProvider, Tooltip } from 'antd'
import { BranchesOutlined, WarningOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import {
  useCircuitDTCsDispatch,
  useCircuitDTCsContext,
} from './CircuitDTCsContext'
import { useRelatedDTCs } from '../hooks/CircuitDTCs'

const Counter = ({ results }) => {
  return (
    <Badge
      color={results?.length > 0 ? 'green' : 'gray'}
      count={results ? results.length : 0}
    />
  )
}

export const RelatedDTCsButton = ({ makeModelId, circuit, loading }) => {
  const dispatch = useCircuitDTCsDispatch()
  const { isLoading: circuitDTCsLoading, dtcs } = useRelatedDTCs({
    makeModelId: makeModelId,
    circuits: [circuit],
  })

  return (
    <Button
      loading={loading || circuitDTCsLoading}
      onClick={() => dispatch.handleCircuitProvided({ makeModelId, circuit })}
      type="default"
      variant="outlined"
      disabled={dtcs?.length == 0}
    >
      <Flex gap="small">
        <FormattedMessage id="circuitDTCs.name" />
        <Counter results={dtcs} />
      </Flex>
    </Button>
  )
}

export const RelatedDTCsIconButton = ({
  makeModelId,
  circuit,
  loading,
  disabled,
  relatedDtcs,
}) => {
  const dispatch = useCircuitDTCsDispatch()

  return (
    <Tooltip
      placement="topLeft"
      title={
        disabled ? (
          <FormattedMessage
            id="circuitDTCs.noRelatedDtcs"
            values={{
              circuit,
            }}
          />
        ) : (
          <FormattedMessage
            id="circuitDTCs.relatedDtcs"
            values={{
              circuit,
              dtcsCount: relatedDtcs.length,
            }}
          />
        )
      }
    >
      <Button
        data-testid="related-dtcs-icon-button"
        loading={loading}
        disabled={disabled}
        icon={<WarningOutlined />}
        onClick={() =>
          dispatch.handleCircuitProvided({
            makeModelId: makeModelId,
            circuit: circuit,
          })
        }
      />
    </Tooltip>
  )
}

export const RelatedCircuitsIconButton = ({
  makeModelId,
  dtc,
  acronym,
  loading,
  disabled,
  relatedCircuits,
}) => {
  const dispatch = useCircuitDTCsDispatch()
  return (
    <Tooltip
      placement="topLeft"
      title={
        disabled ? (
          <FormattedMessage
            id="circuitDTCs.noRelatedCircuits"
            values={{
              dtc,
              acronym,
            }}
          />
        ) : (
          <FormattedMessage
            id="circuitDTCs.relatedCircuits"
            values={{
              dtc,
              acronym,
              circuitsCount: relatedCircuits.length,
            }}
          />
        )
      }
    >
      <Button
        data-testid="related-circuits-icon-button"
        loading={loading}
        disabled={disabled}
        icon={<BranchesOutlined />}
        onClick={() =>
          dispatch.handleDTCProvided({
            makeModelId,
            dtc,
            acronym,
          })
        }
      />
    </Tooltip>
  )
}
