import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Layout, Col, Row, Button, Flex } from 'antd'
import { MoonOutlined, SunOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { navigateToDashboard } from '../history'
import {
  isEmbeddedMode,
  useIsBlockDiagramPreview,
  useFunctionalDiagrams,
  useIsGlobalSearchVinPage,
  useIsGlobalSearchConfigurationPage,
  useIsFunctionalDiagramsVINPage,
} from '../services/navigation'
import Navigation from './Navigation'
import ModalContainer from '../containers/ModalContainer'
import Notification from '../components/Notification'
import Logo from '../components/Logo'
import Routing from '../router'
import RoutingEmbeddedMode from '../EmbeddedMode/router'
import FunctionalDiagramsRouter from '../FunctionalDiagrams/router'
import UserMenu from '../containers/UserMenu'
import VehicleMakeModelName from '../components/VehicleMakeModelName'
import { BlockDiagramPreview } from '../BlockDiagram/BlockDiagram'
import { DiagramProvider } from '../FunctionalDiagrams/DiagramContext'
import { updateTheme } from '../actions/app-actions'
import { DARK_MODE, LIGHT_MODE } from '../constants'
import { GlobalSearchButton } from '../GlobalSearch'

const App = ({ location, customTheme }) => {
  const dispatch = useDispatch()
  const isFunctionalDiagrams = useFunctionalDiagrams()
  const isFunctionalDiagramsVINPage = useIsFunctionalDiagramsVINPage()
  const isBlockDiagramPreview = useIsBlockDiagramPreview()
  const isGlobalSearchVinPage = useIsGlobalSearchVinPage()
  const isGlobalSearchConfigurationPage = useIsGlobalSearchConfigurationPage()
  const { Header, Content } = Layout

  const vin =
    useSelector(
      (state) => state.componentState.vin || state.app.vin,
      shallowEqual,
    ) ||
    (isFunctionalDiagramsVINPage && location.pathname.split('/')[3])
  const notificationContainer = useSelector(
    (state) => state.notificationState.container,
    shallowEqual,
  )
  const modalContainers = useSelector(
    (state) => state.modalState.containers,
    shallowEqual,
  )
  const theme = useSelector((state) => state.app.theme, shallowEqual)

  useEffect(() => {
    Object.keys(customTheme).forEach((key) => {
      document.body.style.setProperty(key, customTheme[key])
    })
  })

  const toggleTheme = () => {
    const newTheme = theme == LIGHT_MODE ? DARK_MODE : LIGHT_MODE
    dispatch(updateTheme(newTheme))
  }

  const renderHeader = () => {
    return (
      <Header className="root-header-container">
        <Row gutter={24}>
          <Col className="header-image-container" span={4}>
            <Logo navigateToDashboard={() => dispatch(navigateToDashboard)} />
          </Col>
          <Col span={1}>
            <GlobalSearchButton
              showVINInTooltip={true}
              vin={
                isGlobalSearchVinPage // Reset to go to the Global Search Configuration Page
                  ? null
                  : vin
              }
              disabled={isGlobalSearchConfigurationPage}
            />
          </Col>
          <Col span={4}>
            {vin && (
              <VehicleMakeModelName
                className="vehicle-make-model vehicle-make-model-menu"
                vin={vin}
                showVin={isFunctionalDiagrams ? true : false}
              />
            )}
          </Col>
          <Col className="root-header-view-selector" span={10}>
            <Navigation
              showViewNavigation={
                location.pathname.includes('/tracer/') ||
                location.pathname.includes('/vehicle-test/preview')
              }
            />
          </Col>
          <Col className="header-user-profile-container" span={5}>
            <UserMenu className="user-menu" />
            <div className="header-theme-selector">
              <Button
                icon={theme == DARK_MODE ? <MoonOutlined /> : <SunOutlined />}
                onClick={toggleTheme}
              />
            </div>
          </Col>
        </Row>
      </Header>
    )
  }

  if (isFunctionalDiagrams) {
    return (
      <Flex className="functional-diagrams-page">
        <Layout className="root-layout">
          {renderHeader()}
          <Content>
            <FunctionalDiagramsRouter />
          </Content>
        </Layout>
        <Notification container={notificationContainer} />
        <ModalContainer containers={modalContainers} />
      </Flex>
    )
  }

  if (isBlockDiagramPreview) {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ height: '100vh', width: '100vw' }}>
          <DiagramProvider>
            <BlockDiagramPreview />
          </DiagramProvider>
        </div>
      </div>
    )
  }

  if (isEmbeddedMode(location.search)) {
    return (
      <>
        <RoutingEmbeddedMode />
        <Notification container={notificationContainer} />
        <ModalContainer containers={modalContainers} />
      </>
    )
  }

  return (
    <>
      <Layout className="root-layout">
        {renderHeader()}
        <Content>
          <Routing />
        </Content>
      </Layout>
      <Notification container={notificationContainer} />
      <ModalContainer containers={modalContainers} />
    </>
  )
}

App.propTypes = {
  notificationContainer: PropTypes.string,
  modalContainers: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object,

  // react-router props
  location: PropTypes.shape({
    pathname: PropTypes.string, // e.g. "/tracer/32023e0b68ae435bbe9c48275113a629:42"
    search: PropTypes.string, // e.g. "?embedded"
    hash: PropTypes.string,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string, // e.g. "/tracer/:pathId"
    url: PropTypes.string, // e.g. "/tracer/32023e0b68ae435bbe9c48275113a629:42"
    params: PropTypes.shape({
      pathId: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
  customTheme: PropTypes.object,
}

export default withRouter(App)
